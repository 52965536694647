import React from 'react';
import { Grid, Image } from 'semantic-ui-react';
import landingImage from '../../assets/images/insurance_landing_v1.svg';

function LoginCard() {
  return (
    <div className="l-login-card">
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column computer={8} mobile={16}>
            <h2>Welcome to Practo Insurance</h2>
            <p> Login to access your Dashboard</p>
            <a href="/login">Login</a>
          </Grid.Column>
          <Grid.Column computer={8} mobile={16}>
            <Image src={landingImage} size="large" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default LoginCard;
