import PropTypes from 'prop-types';
import React from 'react';

function AttributeText({ name, value }) {
  return (
    <div className="attribute">
      <span className="black-text">{name}:</span>
      <b>{value}</b>
    </div>
  );
}

AttributeText.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any
};

export default AttributeText;
