import {
  GET_USER_DETAILS,
  GET_USER_DETAILS_ERROR,
  GET_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_ERROR
} from '../constants/userTypes';

const INITIAL_STATE = {
  isLoading: false,
  data: {},
  error: {
    on: false,
    message: ''
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case GET_USER_DETAILS:
    return {
      ...state,
      isLoading: true
    };
  case GET_USER_DETAILS_SUCCESS:
    return {
      ...state,
      isLoading: false,
      data: action.data
    };
  case UPDATE_USER_DETAILS_SUCCESS:
    return {
      ...state,
      isLoading: false,
      data: {
        ...state.data
      }
    };

  case GET_USER_DETAILS_ERROR:
  case UPDATE_USER_DETAILS_ERROR:
    return {
      ...state,
      isLoading: false,
      error: {
        on: true,
        message: action.error
      }
    };
  default:
    return state;
  }
};
