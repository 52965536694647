import {
  SEND_DOCUMENTS,
  SEND_DOCUMENTS_ERROR,
  SEND_DOCUMENTS_SUCCESS,
  RESET_DOCUMENTS
} from '../constants/ratecardTypes';

const INITIAL_STATE = {
  isLoading: false,
  data: {},
  error: {
    on: false,
    message: ''
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case SEND_DOCUMENTS:
    return {
      ...state,
      isLoading: true,
      error: {
        on: false,
        message: ''
      }
    };

  case SEND_DOCUMENTS_SUCCESS:
    return {
      ...state,
      isLoading: false,
      data: action.data
    };

  case SEND_DOCUMENTS_ERROR:
    return {
      ...state,
      isLoading: false,
      error: {
        on: true,
        message: 'Could not upload documents'
      }
    };
  case RESET_DOCUMENTS:
    return INITIAL_STATE;

  default:
    return state;
  }
};
