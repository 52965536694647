import { clearFields } from 'redux-form';
import { SHOW_ERROR } from '../constants/errorTypes';
import {
  GET_LOCALITY,
  GET_LOCALITY_ERROR,
  GET_MASTER_CITIES,
  GET_MASTER_CITIES_ERROR,
  GET_MASTER_SPECIALITIES,
  GET_MASTER_SPECIALITIES_ERROR,
  GET_PAYOR_PLANS,
  GET_PAYOR_PLANS_ERROR,
  RESET_LOCALITIES,
  SET_TAB_TYPE,
  SET_BANK_DETAILS_ENABLED
} from '../constants/filtersTypes';
import api from '../helpers/api';
import store from '../store';

const _fieldToClearList = name => {
  const { form } = store.getState();
  if (!form[name].values) return clearFields(name);
  const fields = Object.keys(form[name].values);

  return fields.filter(field => field !== 'payor' && field !== 'policy_id');
};

export const clearFieldsAction = (
  name,
  fieldsToClear = _fieldToClearList(name)
) => {
  return dispatch => {
    dispatch(clearFields(name, false, false, ...fieldsToClear));
  };
};

export const fetchMasterSpecialities = payorId => {
  return dispatch => {
    api
      .getAllSpecialities(payorId)
      .then(({ data }) => {
        dispatch({
          type: GET_MASTER_SPECIALITIES,
          data
        });
      })
      .catch(() => {
        dispatch({ type: GET_MASTER_SPECIALITIES_ERROR });
        dispatch({ type: SHOW_ERROR });
      });
  };
};

export const fetchMasterCities = payorId => {
  return dispatch => {
    api
      .getAllCities(payorId)
      .then(({ data }) => {
        dispatch({
          type: GET_MASTER_CITIES,
          data
        });
      })
      .catch(() => {
        dispatch({ type: GET_MASTER_CITIES_ERROR });
        dispatch({ type: SHOW_ERROR });
      });
  };
};

const _getPayorIdFromStore = () => {
  /* Destructures and assigns value of payor inside form.filter.values
    with fallback ( = {} ) to handle errors
  */
  const {
    form: { filter: { values: { payor } = {} } = {} } = {}
  } = store.getState();
  return payor;
};

export const fetchLocalities = city => {
  const payorId = _getPayorIdFromStore();

  return dispatch => {
    api
      .getLocalities(payorId, city)
      .then(({ data }) => {
        dispatch({
          type: GET_LOCALITY,
          data
        });
      })
      .catch(() => {
        dispatch({ type: GET_LOCALITY_ERROR });
        dispatch({ type: SHOW_ERROR });
      });
  };
};

export const fetchPayorPlans = payorId => {
  return dispatch => {
    api
      .getPayorPlans(payorId)
      .then(({ data }) => {
        dispatch({
          type: GET_PAYOR_PLANS,
          data
        });
      })
      .catch(() => {
        dispatch({ type: GET_PAYOR_PLANS_ERROR });
        dispatch({ type: SHOW_ERROR });
      });
  };
};

export const setTabType = index => {
  return dispatch => {
    dispatch({
      type: SET_TAB_TYPE,
      index
    });
    return Promise.resolve();
  };
};

export const resetLocalities = () => {
  return dispatch => {
    dispatch({
      type: RESET_LOCALITIES
    });
  };
};

export const setBankDetailsEnabled = payorName => {
  return dispatch => {
    dispatch({
      type: SET_BANK_DETAILS_ENABLED,
      data: payorName === 'Practo Subscriptions'
    });
  };
};
