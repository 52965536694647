import {
  GET_MASTER_SPECIALITIES,
  GET_MASTER_SPECIALITIES_ERROR,
  GET_MASTER_CITIES,
  GET_MASTER_CITIES_ERROR,
  GET_LOCALITY,
  GET_LOCALITY_ERROR,
  GET_PAYOR_PLANS,
  GET_PAYOR_PLANS_ERROR,
  RESET_LOCALITIES,
  SET_TAB_TYPE,
  SET_BANK_DETAILS_ENABLED
} from '../constants/filtersTypes';

const INITIAL_STATE = {
  specialities: [],
  cities: [],
  localities: [],
  plans: [],
  type: 'MASTER', // Tab Type : MASTER (default) or SHORTLISTED
  errorSpecialities: null,
  errorCities: null,
  errorLocalities: null,
  errorPlans: null,
  isBankDetailsEnabled: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case RESET_LOCALITIES:
    return {
      ...state,
      localities: []
    };
  case GET_MASTER_SPECIALITIES:
    return {
      ...state,
      specialities: action.data.specialities
    };

  case GET_MASTER_SPECIALITIES_ERROR:
    return {
      ...state,
      errorSpecialities: "Couldn't fetch Specialities"
    };

  case GET_MASTER_CITIES:
    return {
      ...state,
      cities: action.data.cities
    };

  case GET_MASTER_CITIES_ERROR:
    return {
      ...state,
      errorCities: "Couldn't fetch Cities"
    };

  case GET_LOCALITY:
    return {
      ...state,
      localities: action.data.localities
    };

  case GET_LOCALITY_ERROR:
    return {
      ...state,
      errorLocalities: "Couldn't fetch Cities"
    };

  case GET_PAYOR_PLANS:
    return {
      ...state,
      plans: action.data.policies
    };

  case GET_PAYOR_PLANS_ERROR:
    return {
      ...state,
      errorPlans: "Couldn't fetch Plans"
    };

  case SET_TAB_TYPE:
    return {
      ...state,
      type: action.index === 0 ? 'MASTER' : 'SHORTLISTED'
    };

  case SET_BANK_DETAILS_ENABLED:
    return {
      ...state,
      isBankDetailsEnabled: action.data
    };

  default:
    return state;
  }
};
