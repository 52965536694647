import pick from 'lodash/pick';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Divider,
  Grid,
  Icon,
  Label,
  Popup,
  Segment
} from 'semantic-ui-react';
import {
  AttributeText,
  Checkbox,
  ConfirmModal,
  UploadBankDetailsModal,
  UploadRatecardModal,
  ViewRatecardModal
} from '..';
import { fetchContractLink } from '../../actions/contract';
import { unmarkCashless, downloadQRCode } from '../../actions/doctors';
import { fetchMasterProcedures } from '../../actions/procedures';
import { fetchRatecard } from '../../actions/ratecard';
import messages from '../../constants/messages';
import { getHost } from '../../helpers/url';
import { downloader } from '../../helpers/utils';

class DoctorCard extends Component {
  constructor() {
    super();
    this.state = {
      openModal: false,
      openConfirm: false,
      openUploadModal: false,
      showBankDetailsModal: false
    };
    this.confirmText = messages.unmarkCashlessText;
    this.handleRateCardClose = this.handleRateCardClose.bind(this);
    this.handleUploadRateCardClose = this.handleUploadRateCardClose.bind(this);
    this.handleUnmarkConfirmClose = this.handleUnmarkConfirmClose.bind(this);
    this.unmarkCashless = this.unmarkCashless.bind(this);
    this.viewRateCard = this.viewRateCard.bind(this);
    this.viewUnmarkModal = this.viewUnmarkModal.bind(this);
    this.viewUploadModal = this.viewUploadModal.bind(this);
    this.renderViewRatecardButton = this.renderViewRatecardButton.bind(this);
    this.renderCashlessLabel = this.renderCashlessLabel.bind(this);
    this.renderOnboardDoctorButton = this.renderOnboardDoctorButton.bind(this);
    this.renderUnmarkCashlessButton = this.renderUnmarkCashlessButton.bind(
      this
    );
    this.renderLabel = this.renderLabel.bind(this);
    this.handleQRCodeDownload = this.handleQRCodeDownload.bind(this);
    this.renderUploadButton = this.renderUploadButton.bind(this);
    this.openBankDetailsModal = this.openBankDetailsModal.bind(this);
    this.closeBankDetailsModal = this.closeBankDetailsModal.bind(this);
  }

  renderConsultationTimings() {
    const { clinic_timings } = this.props;
    if (!clinic_timings) return '';

    const clinicTimings = JSON.parse(clinic_timings);

    return Object.keys(clinicTimings).map(item => (
      <div className="attribute" key={item}>
        <b>{item}</b>: {clinicTimings[item]}
      </div>
    ));
  }

  viewRateCard(e) {
    DoctorCard.stopBubbling(e);
    const {
      ratecard_id,
      fetchRatecard,
      fetchContractLink,
      contract_url
    } = this.props;
    Promise.all([fetchRatecard(ratecard_id), fetchContractLink(contract_url)])
      .then(() => {
        this.setState({
          openModal: true
        });
      })
      .catch(err => {
        // TODO: Notify user on error
      });
  }

  viewUnmarkModal(e) {
    DoctorCard.stopBubbling(e);
    this.setState({
      openConfirm: true
    });
  }

  viewUploadModal(e) {
    DoctorCard.stopBubbling(e);
    const {
      filters: { values: { payor } = {} } = {},
      fetchMasterProcedures
    } = this.props;
    fetchMasterProcedures(payor)
      .then(() => {
        this.setState({
          openUploadModal: true
        });
      })
      .catch(() => {
        // TODO: Notify user on error
      });
  }

  unmarkCashless(e) {
    DoctorCard.stopBubbling(e);
    const payload = pick(this.props, [
      'policy_id',
      'doctor_id',
      'doctor_policy_id',
      'ratecard_id'
    ]);
    this.props.unmarkCashless(payload).then(() => this.props.reload());
    this.handleUnmarkConfirmClose();
  }

  handleUnmarkConfirmClose() {
    this.setState({
      openConfirm: false
    });
  }

  handleRateCardClose() {
    this.setState({
      openModal: false
    });
  }

  handleUploadRateCardClose() {
    this.setState({
      openUploadModal: false
    });
  }

  static stopBubbling(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  isRatecardLoading() {
    return (
      this.props.ratecard_id === this.props.selectedRatecardId &&
      this.props.ratecardLoading
    );
  }

  isQRButtonLoading() {
    return (
      this.props.doctor_id === this.props.selectedDoctorId &&
      this.props.isQRCodeLoading
    );
  }

  handleQRCodeDownload(e) {
    DoctorCard.stopBubbling(e);
    const { practice_id, doctor_id } = this.props;

    this.props
      .downloadQRCode(practice_id, doctor_id)
      .then(data => downloader(data, `${practice_id}.pdf`));
  }

  renderViewRatecardButton() {
    return (
      <Fragment>
        <Button
          basic
          className="btn"
          compact
          color="yellow"
          onClick={this.viewRateCard}
          size="tiny"
          loading={this.isRatecardLoading()}
        >
          View Ratecard
        </Button>
        {this.state.openModal && (
          <ViewRatecardModal
            {...this.props}
            open={this.state.openModal}
            handleClose={this.handleRateCardClose}
          />
        )}
      </Fragment>
    );
  }

  renderUnmarkCashlessButton() {
    return (
      <Fragment>
        <Button
          basic
          className="btn"
          compact
          color="yellow"
          onClick={this.viewUnmarkModal}
          size="tiny"
        >
          Unmark Cashless
        </Button>
        {this.state.openConfirm && (
          <ConfirmModal
            open={this.state.openConfirm}
            className="unmark-confirm"
            cancelButton="No, go back"
            confirmButton="Yes, I'm sure"
            content={this.confirmText}
            header="Are you sure?"
            onCancel={this.handleUnmarkConfirmClose}
            onConfirm={this.unmarkCashless}
            size="small"
          />
        )}
      </Fragment>
    );
  }

  renderCashlessLabel() {
    const { modification_time } = this.props;
    return (
      <Fragment>
        <Popup
          trigger={
            <Button
              compact
              loading={this.isQRButtonLoading()}
              disabled={this.isQRButtonLoading()}
              size="tiny"
              icon
              color="yellow"
              onClick={this.handleQRCodeDownload}
            >
              <Icon name="qrcode" />
            </Button>
          }
          size="mini"
          position="bottom center"
          content="Download QR Code for Practice"
          inverted
        />
        <span className="vertical-divider">|</span>
        <Label color="green" size="mini" image>
          CASHLESS
          <Label.Detail>
            {moment(modification_time).format('DD/MM/YYYY')}
          </Label.Detail>
        </Label>
      </Fragment>
    );
  }

  renderOnboardDoctorButton() {
    return (
      <Fragment>
        <Button
          basic
          className="btn"
          compact
          color="yellow"
          onClick={this.viewUploadModal}
          size="tiny"
          disabled={this.props.proceduresLoading}
        >
          Onboard Doctor
        </Button>
        {this.state.openUploadModal && (
          <UploadRatecardModal
            {...this.props}
            reload={this.props.reload}
            open={this.state.openUploadModal}
            handleClose={this.handleUploadRateCardClose}
          />
        )}
      </Fragment>
    );
  }

  renderLabel() {
    const { contract_status, modification_time, is_owner, isBankDetailsEnabled } = this.props;

    const reimbursementLabel = (
      <Label color="black" size="mini">
        REIMBURSEMENT
      </Label>
    );

    switch (contract_status.toUpperCase()) {
    case 'NA':
      return (
          <Fragment>
            {this.renderUploadButton()}
            {isBankDetailsEnabled && is_owner ? <span className="vertical-divider">|</span> : null}
            {reimbursementLabel}
          </Fragment>
      );
    case 'PENDING':
      return (
          <Fragment>
            {this.renderUploadButton()}
            <Popup
              trigger={
                <Button
                  compact
                  loading={this.isQRButtonLoading()}
                  disabled={this.isQRButtonLoading()}
                  size="tiny"
                  icon
                  color="yellow"
                  onClick={this.handleQRCodeDownload}
                >
                  <Icon name="qrcode" />
                </Button>
              }
              size="mini"
              position="bottom center"
              content="Download QR Code for Practice"
              inverted
            />
            <span className="vertical-divider">|</span>
            <Label color="yellow" size="mini" image>
              PENDING
              <Label.Detail>
                {moment(modification_time).format('DD/MM/YYYY')}
              </Label.Detail>
            </Label>
            {reimbursementLabel}
          </Fragment>
      );
    case 'REJECTED':
      return (
          <Fragment>
            {this.renderUploadButton()}
            <span className="vertical-divider">|</span>

            <Label color="red" size="mini" image>
              REJECTED
              <Label.Detail>
                {moment(modification_time).format('DD/MM/YYYY')}
              </Label.Detail>
            </Label>
            {reimbursementLabel}
          </Fragment>
      );
    default:
      return null;
    }
  }

  renderUploadButton() {
    const { is_owner, isBankDetailsEnabled } = this.props;
    if (isBankDetailsEnabled && is_owner) {
      return (
        <Fragment>
          <Popup
            trigger={
              <Button
                compact
                size="tiny"
                icon
                color="yellow"
                onClick={this.openBankDetailsModal}
              >
                <Icon name="upload" />
              </Button>
            }
            size="mini"
            position="bottom center"
            content="Upload Bank details"
            inverted
          />
          {this.state.showBankDetailsModal && (
            <UploadBankDetailsModal
              {...this.props}
              reload={this.props.reload}
              open={this.state.showBankDetailsModal}
              handleClose={this.closeBankDetailsModal}
            />
          )}
        </Fragment>
      );
    }
    return null;
  }

  openBankDetailsModal(e) {
    DoctorCard.stopBubbling(e);
    this.setState({
      showBankDetailsModal: true
    });
  }

  closeBankDetailsModal() {
    this.setState({
      showBankDetailsModal: false
    });
  }

  render() {
    const {
      abs_status,
      appointment_volume,
      city,
      college,
      consultation_fee,
      contract_status,
      doctor_id,
      doctor_name,
      doctor_slug,
      grade,
      locality,
      network_type,
      practice_name,
      qualifications,
      ray_doctor_id,
      ray_practice_id,
      ray_subscription,
      recommendation_score,
      selectedDoctors,
      speciality,
      years_of_experience
    } = this.props;

    return (
      <Grid.Column className="doctor-card">
        <Segment
          onClick={() => this.props.onSelectDoctor(doctor_id)}
          className={selectedDoctors.indexOf(doctor_id) > -1 ? 'active' : null}
        >
          <Checkbox
            className="card-checkbox"
            checked={selectedDoctors.indexOf(doctor_id) > -1}
          />
          <Grid stackable columns={2}>
            <Grid.Column>
              <div className="name" title={doctor_name}>
                <b>{doctor_name}</b>{' '}
                {ray_doctor_id && (
                  <span>
                    {'('}
                    ID: {ray_doctor_id}
                    {')'}
                  </span>
                )}
              </div>
              <div className="attribute grey-text">
                <span className="half-column" title={speciality}>
                  {speciality}
                </span>
                <span className="half-column">
                  {doctor_slug && (
                    <a
                      className="profile-url"
                      href={`${getHost('www')}/bangalore/doctor/${doctor_slug}`}
                      onClick={e => e.stopPropagation()}
                      target="_blank"
                    >
                      View Profile
                    </a>
                  )}
                </span>
              </div>
              <div className="attribute" title={qualifications}>
                {qualifications}
              </div>
              <div className="attribute grey-text" title={college}>
                {college}
              </div>
            </Grid.Column>
            <Grid.Column>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column width={10}>
                    <AttributeText
                      name="Consultation"
                      value={consultation_fee}
                    />
                  </Grid.Column>
                  <Grid.Column textAlign="center" width={6}>
                    {abs_status === 0 ? (
                      <Label basic size="mini" color="grey">
                        ABS: OFF
                      </Label>
                    ) : (
                      <Label basic size="mini" color="green">
                        ABS: ON
                      </Label>
                    )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <AttributeText
                name="Recommendation"
                value={recommendation_score}
              />
              <AttributeText name="Years of Exp" value={years_of_experience} />
              <AttributeText
                name="Appointment volume"
                value={appointment_volume}
              />
              <AttributeText name="Class" value={grade} />
            </Grid.Column>
            <Divider className="card-divider" />
            <Grid.Column className="practice-details">
              <div className="practice-name">
                <span className="half-column name" title={practice_name}>
                  <b>{practice_name}</b>{' '}
                </span>
                <span className="half-column name">
                  {ray_practice_id && (
                    <span>
                      {'('}
                      ID: {ray_practice_id}
                      {')'}
                    </span>
                  )}
                </span>
              </div>
              <div
                className="attribute grey-text"
                title={`${locality || 'NA'}, ${city || 'NA'}`}
              >
                {`${locality || 'NA'}, ${city || 'NA'}`}
              </div>
              <div className="attribute" title={ray_subscription}>
                {ray_subscription &&
                  ray_subscription.toUpperCase() === 'ACTIVE' && (
                    <Label size="mini" color="green">
                      RAY
                    </Label>
                  )}
              </div>
            </Grid.Column>
            <Grid.Column>{this.renderConsultationTimings()}</Grid.Column>
            {network_type && (
              <Grid.Row className="action">
                {network_type === 'CASHLESS' ? (
                  <Fragment>
                    <Grid.Column width={8}>
                      {this.renderViewRatecardButton()}|
                      {this.renderUnmarkCashlessButton()}
                    </Grid.Column>
                    <Grid.Column width={8} textAlign="right">
                      {this.renderUploadButton()}
                      {this.renderCashlessLabel()}
                    </Grid.Column>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Grid.Column width={6}>
                      {contract_status === 'NA' ||
                      contract_status === 'REJECTED'
                        ? this.renderOnboardDoctorButton()
                        : this.renderViewRatecardButton()}
                    </Grid.Column>
                    <Grid.Column width={10} textAlign="right">
                      {this.renderLabel()}
                    </Grid.Column>
                  </Fragment>
                )}
              </Grid.Row>
            )}
          </Grid>
        </Segment>
      </Grid.Column>
    );
  }
}

DoctorCard.propTypes = {
  abs_status: PropTypes.oneOf([0, 1]),
  appointment_volume: PropTypes.string,
  city: PropTypes.string,
  clinic_timings: PropTypes.string,
  college: PropTypes.string,
  consultation_fee: PropTypes.number,
  contract_status: PropTypes.string,
  contract_url: PropTypes.string,
  doctor_id: PropTypes.number,
  doctor_name: PropTypes.string,
  doctor_slug: PropTypes.string,
  downloadQRCode: PropTypes.func.isRequired,
  fetchContractLink: PropTypes.func.isRequired,
  fetchMasterProcedures: PropTypes.func.isRequired,
  fetchRatecard: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  grade: PropTypes.string,
  is_owner: PropTypes.oneOf([0, 1]),
  isQRCodeLoading: PropTypes.bool.isRequired,
  locality: PropTypes.string,
  modification_time: PropTypes.string,
  network_type: PropTypes.string,
  onSelectDoctor: PropTypes.func.isRequired,
  practice_id: PropTypes.number.isRequired,
  practice_name: PropTypes.string,
  proceduresLoading: PropTypes.bool.isRequired,
  qualifications: PropTypes.string,
  ratecard_id: PropTypes.number,
  ratecardLoading: PropTypes.bool.isRequired,
  ray_doctor_id: PropTypes.number,
  ray_practice_id: PropTypes.number,
  ray_subscription: PropTypes.string,
  recommendation_score: PropTypes.number,
  reload: PropTypes.func.isRequired,
  selectedDoctorId: PropTypes.number,
  selectedDoctors: PropTypes.array,
  selectedRatecardId: PropTypes.number,
  speciality: PropTypes.string,
  unmarkCashless: PropTypes.func.isRequired,
  years_of_experience: PropTypes.number,
  isBankDetailsEnabled: PropTypes.bool.isRequired,
};

export default connect(
  state => ({
    doctorIds: state.form.doctor,
    filters: state.form.filter,
    isQRCodeLoading: state.download.isQRCodeLoading,
    proceduresLoading: state.procedures.isLoading,
    ratecardLoading: state.ratecard.isLoading,
    selectedDoctors: state.selectedDoctors,
    selectedDoctorId: state.download.doctorId,
    selectedRatecardId: state.ratecard.id,
    isBankDetailsEnabled: state.masterData.isBankDetailsEnabled
  }),
  {
    fetchMasterProcedures,
    fetchRatecard,
    fetchContractLink,
    downloadQRCode,
    unmarkCashless
  }
)(DoctorCard);
