import { SHOW_ERROR } from '../constants/errorTypes';
import {
  FETCH_RATECARD,
  FETCH_RATECARD_ERROR,
  FETCH_RATECARD_SUCCESS
} from '../constants/ratecardTypes';
import api from '../helpers/api';

export const fetchRatecard = ratecardId => {
  return dispatch => {
    dispatch({
      type: FETCH_RATECARD,
      id: ratecardId
    });

    return api
      .getRatecard(ratecardId)
      .then(({ data }) => {
        dispatch({
          type: FETCH_RATECARD_SUCCESS,
          data
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_RATECARD_ERROR,
          error: error.message
        });
        dispatch({ type: SHOW_ERROR });
        throw new Error({ message: error.message });
      });
  };
};
