import PropTypes from 'prop-types';
import React from 'react';
import { Button, Card, Dimmer, Image } from 'semantic-ui-react';
import profileIcon from '../../assets/images/profile-icon_v1.png';

const AccessDeniedCard = ({ name }) => {
  return (
    <Dimmer active>
      <Card centered color="red">
        <Card.Content>
          <Image floated="right" size="mini" src={profileIcon} />
          <Card.Header>{name}</Card.Header>
          <Card.Description>
            <strong>Your request for access is under approval</strong>
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          <div className="ui one buttons">
            <a href="/logout">
              <Button basic color="red">
                Logout
              </Button>
            </a>
          </div>
        </Card.Content>
      </Card>
    </Dimmer>
  );
};

AccessDeniedCard.propTypes = {
  name: PropTypes.string
};

export default AccessDeniedCard;
