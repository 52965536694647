import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

function ClinicInfo({ practice: { name, owner_name, address } }) {
  return (
    <div>
      <p>
        <b>To,</b>
      </p>
      <p>
        <b>Clinic Name: </b>
        {name}
      </p>
      <p>
        <b>Clinic Owner Name: </b>
        {owner_name}
      </p>
      <p>
        <b>Relevant Designation: </b>Owner
      </p>
      <p>
        <b>Address: </b>
        {address}
      </p>
      <p>
        <b>Date: </b>
        {moment().format('Do MMM YYYY')}
      </p>
      <br />
    </div>
  );
}

ClinicInfo.propTypes = {
  practice: PropTypes.object.isRequired
};

export default ClinicInfo;
