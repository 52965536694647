import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Table } from 'semantic-ui-react';

function RatecardTable({ ratecards }) {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Table unstackable compact size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name of Doctor</Table.HeaderCell>
                <Table.HeaderCell>Specialisation</Table.HeaderCell>
                <Table.HeaderCell>
                  Consultation Fees at the pre decided rate
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {Array.isArray(ratecards)
                && ratecards.map(ratecard => (
                    <Table.Row key={ratecard.id}>
                      <Table.Cell>{ratecard.name || ''}</Table.Cell>
                      <Table.Cell>{ratecard.speciality || '-'}</Table.Cell>
                      <Table.Cell textAlign="center">
                        {ratecard.sample_rate
                          ? `₹ ${ratecard.sample_rate}`
                          : '-'}
                      </Table.Cell>
                    </Table.Row>
                  ))}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}

RatecardTable.propTypes = {
  ratecards: PropTypes.array.isRequired
};

export default RatecardTable;
