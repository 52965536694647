import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import {
  ConfirmationPage,
  LandingPage,
  PopupPage,
  RatecardPage
} from '.';
import { Navbar, SuccessModal } from '../../components';
import {
  getPracticeDetailsFromToken,
  sendInterest
} from '../../helpers/lead-gen-api';
import '../../styles/lead-generation/main.scss';

class Main extends Component {
  constructor(props) {
    super();
    const { source, token, email } = qs.parse(props.location.search);
    this.state = {
      source,
      token,
      email,
      error: false
    };

    this.closeModal = this.closeModal.bind(this);
  }

  componentWillMount() {
    const { source, token, email } = this.state;
    if (source === 'email') {
      const payload = {
        step: 1,
        interested: true,
        source
      };

      if (email) {
        payload.email = email;
      }

      sendInterest(token, payload);
      getPracticeDetailsFromToken(token)
        .then(({ data }) => {
          this.props.history.push(`${this.props.match.url}/ratecard`, {
            data,
            source,
            token,
            email
          });
        })
        .catch(() => {
          this.setState({
            error: true
          });
        });
    }
  }

  closeModal() {
    this.setState({
      error: false
    });
  }

  render() {
    const { match } = this.props;
    return this.state.source === 'ray' ? (
      <Container fluid className="popup-container">
        <Route exact path={match.url} component={PopupPage} />
        <Route path={`${match.url}/ratecard`} component={RatecardPage} />
        <Route path={`${match.url}/success`} component={ConfirmationPage} />
        {this.state.error && (
          <SuccessModal
            actionText="OKAY"
            handleClick={this.closeModal}
            header="Something went wrong"
            message="Please try again."
            open={this.state.error}
            size="mini"
          />
        )}
      </Container>
    ) : (
      <Fragment>
        <Navbar size="huge" />
        <Container className="main-container">
          <Route exact path={match.url} component={LandingPage} />
          <Route path={`${match.url}/ratecard`} component={RatecardPage} />
          <Route path={`${match.url}/success`} component={ConfirmationPage} />
          {this.state.error && (
            <SuccessModal
              actionText="OKAY"
              handleClick={this.closeModal}
              header="Something went wrong"
              message="Please try again."
              open={this.state.error}
              size="mini"
            />
          )}
        </Container>
      </Fragment>
    );
  }
}

Main.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default Main;
