import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Button, Grid, Icon } from 'semantic-ui-react';
import { DropdownFilter, RatingInput, SearchInput } from '..';
import { resetDoctorList } from '../../actions/doctors';
import {
  clearFieldsAction,
  fetchLocalities,
  fetchMasterCities,
  fetchMasterSpecialities,
  fetchPayorPlans,
  resetLocalities,
  setBankDetailsEnabled
} from '../../actions/filters';
import { isDirty } from '../../helpers/utils';

class FiltersBar extends Component {
  constructor() {
    super();
    this.state = {
      filtersText: 'Advanced',
      filtersIcon: 'chevron down',
      expanded: false,
      payorSelected: false,
      planSelected: false
    };

    this.handleCityChange = this.handleCityChange.bind(this);
    this.handlePlanChange = this.handlePlanChange.bind(this);
  }

  toggleFiltersText() {
    this.setState({
      filtersText:
        this.state.filtersText === 'Advanced' ? 'Show less' : 'Advanced',
      filtersIcon:
        this.state.filtersIcon === 'chevron down'
          ? 'chevron up'
          : 'chevron down',
      expanded: !this.state.expanded
    });
  }

  payorList() {
    const { user: { payors } = {} } = this.props;
    return payors.map(payor => ({
      text: payor.name,
      value: payor.id
    }));
  }
  planList() {
    const { masterPlans } = this.props;
    return masterPlans.map(plan => ({
      text: plan.name,
      value: plan.id
    }));
  }
  specialityList() {
    const { masterSpecialities } = this.props;
    return masterSpecialities.map(name => ({
      text: name,
      value: name
    }));
  }
  cityList() {
    const { masterCities } = this.props;
    return masterCities.map(name => ({
      text: name,
      value: name
    }));
  }
  localityList() {
    const { masterLocalities } = this.props;
    return masterLocalities.map(name => ({
      text: name,
      value: name
    }));
  }
  static experienceList() {
    const MAX_EXPERIENCE = 100;
    return Array(MAX_EXPERIENCE)
      .fill(1)
      .map((val, index) => ({
        text: index,
        value: index
      }));
  }
  static consultationList() {
    const MAX_CONSULTATION_STEP = 50;
    return Array(MAX_CONSULTATION_STEP)
      .fill(100)
      .map((val, index) => ({
        text: val * index,
        value: val * index
      }));
  }
  static activeRayList() {
    const values = ['Yes', 'No'];
    return values.map(value => ({
      text: `Active Ray: ${value}`,
      value
    }));
  }
  static absStatusList() {
    const values = ['On', 'Off'];
    return values.map(value => ({
      text: `ABS Status: ${value}`,
      value
    }));
  }
  static onboardedStatusList() {
    const statusArray = ['Approved', 'Pending', 'Rejected'];
    return statusArray.map(status => ({
      text: status,
      value: status.toUpperCase()
    }));
  }

  handlePayorChange(value) {
    const {
      fetchMasterCities,
      fetchMasterSpecialities,
      fetchPayorPlans,
      user
    } = this.props;

    this.props.clearFieldsAction('filter', ['policy_id']);
    this.props.resetDoctorList();

    const selectedPayor = user.payors.find(payor => payor.id === value);
    if (selectedPayor) {
      this.props.setBankDetailsEnabled(selectedPayor.name);
    }

    Promise.all([
      fetchMasterCities(value),
      fetchMasterSpecialities(value),
      fetchPayorPlans(value)
    ]).then(() => {
      this.setState({
        payorSelected: true
      });
    });
  }

  handlePlanChange() {
    this.setState({
      planSelected: true
    });
  }

  handleCityChange(value) {
    const { fetchLocalities } = this.props;
    this.props.clearFieldsAction('filter', ['locality']);
    fetchLocalities(value);
  }

  resetFields(e) {
    e.preventDefault();
    this.props.resetLocalities();
    this.props.clearFieldsAction('filter');
  }

  render() {
    return (
      <Grid stackable className="l-filters-bar">
        <Grid.Row columns={5}>
          <Grid.Column>
            <Field
              name="payor"
              component={DropdownFilter}
              selection
              placeholder="Select a Payor"
              options={this.payorList()}
              handleChange={this.handlePayorChange.bind(this)}
            />
          </Grid.Column>
          {this.state.payorSelected && (
            <Fragment>
              <Grid.Column>
                <Field
                  name="policy_id"
                  component={DropdownFilter}
                  placeholder="Select a Plan"
                  selection
                  options={this.planList()}
                  handleChange={this.handlePlanChange}
                />
              </Grid.Column>
              {this.state.planSelected && (
                <Fragment>
                  <Grid.Column>
                    <Field
                      name="speciality"
                      component={DropdownFilter}
                      placeholder="Select a Speciality"
                      search
                      selection
                      options={this.specialityList()}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Field
                      name="city"
                      component={DropdownFilter}
                      placeholder="Select a City"
                      search
                      selection
                      options={this.cityList()}
                      handleChange={this.handleCityChange}
                    />
                  </Grid.Column>
                  <Grid.Column className="advanced-container">
                    <span
                      className="advanced-btn"
                      onClick={this.toggleFiltersText.bind(this)}
                    >
                      <a onClick={event => event.preventDefault()}>
                        {this.state.filtersText}{' '}
                      </a>
                      <Icon name={this.state.filtersIcon} />
                    </span>
                    <Button
                      basic
                      size="mini"
                      className="clear-filter-btn"
                      onClick={this.resetFields.bind(this)}
                    >
                      Clear Filters
                    </Button>
                  </Grid.Column>
                </Fragment>
              )}
            </Fragment>
          )}
        </Grid.Row>
        {this.state.payorSelected &&
          this.state.expanded && (
            <Fragment>
              <Grid.Row columns={5}>
                <Grid.Column>
                  <Field
                    name="locality"
                    component={DropdownFilter}
                    placeholder="Select a Locality"
                    search
                    selection
                    disabled={!Boolean(this.props.masterLocalities.length)}
                    options={this.localityList()}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Field
                    name="doctor_name"
                    component={SearchInput}
                    placeholder="Search by Doctor name / ID"
                  />
                </Grid.Column>
                <Grid.Column>
                  <Field
                    name="practice_name"
                    component={SearchInput}
                    placeholder="Search by Clinic name / ID"
                  />
                </Grid.Column>
                <Grid.Column>
                  <Field
                    name="experience"
                    component={DropdownFilter}
                    placeholder="Min. years of Experience"
                    search
                    selection
                    options={FiltersBar.experienceList()}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Field name="score_min" component={RatingInput} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={5}>
                <Grid.Column>
                  <Field
                    name="consultation_max"
                    component={DropdownFilter}
                    placeholder="Max. Consultation Fee"
                    search
                    selection
                    options={FiltersBar.consultationList()}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Field
                    name="ray_subscription"
                    component={DropdownFilter}
                    placeholder="Active Ray"
                    selection
                    options={FiltersBar.activeRayList()}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Field
                    name="abs_status"
                    component={DropdownFilter}
                    placeholder="ABS Status"
                    selection
                    options={FiltersBar.absStatusList()}
                  />
                </Grid.Column>
                {this.props.type !== 'MASTER' && (
                  <Grid.Column>
                    <Field
                      name="onboarded_status"
                      component={DropdownFilter}
                      placeholder="Onboarded Status"
                      selection
                      options={FiltersBar.onboardedStatusList()}
                    />
                  </Grid.Column>
                )}
              </Grid.Row>
            </Fragment>
          )}
      </Grid>
    );
  }
}

FiltersBar.propTypes = {
  user: PropTypes.object.isRequired,
  masterSpecialities: PropTypes.array.isRequired,
  masterCities: PropTypes.array.isRequired,
  masterLocalities: PropTypes.array.isRequired,
  masterPlans: PropTypes.array.isRequired,
  clearFieldsAction: PropTypes.func.isRequired,
  fetchMasterCities: PropTypes.func.isRequired,
  fetchMasterSpecialities: PropTypes.func.isRequired,
  fetchLocalities: PropTypes.func.isRequired,
  fetchPayorPlans: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  resetLocalities: PropTypes.func.isRequired,
  resetDoctorList: PropTypes.func.isRequired,
  setBankDetailsEnabled: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

export default connect(
  state => ({
    user: state.user.data,
    masterSpecialities: state.masterData.specialities,
    masterCities: state.masterData.cities,
    masterLocalities: state.masterData.localities,
    masterPlans: state.masterData.plans,
    type: state.masterData.type
  }),
  {
    clearFieldsAction,
    fetchMasterCities,
    fetchMasterSpecialities,
    fetchLocalities,
    fetchPayorPlans,
    resetLocalities,
    resetDoctorList,
    setBankDetailsEnabled
  }
)(
  reduxForm({
    form: 'filter',
    onChange: (values, dispatch, props, previousValues) => {
      // Avoid unnecessary api call without setting initial value of the fields
      if (
        !(values.hasOwnProperty('payor') && values.hasOwnProperty('policy_id'))
      )
        return;

      if (
        !previousValues.hasOwnProperty('experience') &&
        values.experience === 0
      )
        return;
      if (
        !previousValues.hasOwnProperty('consultation_max') &&
        values.consultation_max === 0
      )
        return;

      if (values.offset && !isDirty('offset', values, previousValues)) {
        values.offset = 0;
      }
      props.onSubmit(values);
    }
  })(FiltersBar)
);
