import {
  FETCH_RATECARD,
  FETCH_RATECARD_SUCCESS,
  FETCH_RATECARD_ERROR
} from '../constants/ratecardTypes';

const INITIAL_STATE = {
  isLoading: false,
  id: null,
  data: {
    ratecard: {
      procedures: []
    }
  },
  error: {
    on: false,
    message: ''
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case FETCH_RATECARD:
    return {
      ...state,
      isLoading: true,
      id: action.id
    };
  case FETCH_RATECARD_SUCCESS:
    return {
      ...state,
      isLoading: false,
      data: {
        ratecard: action.data.ratecard
      }
    };
  case FETCH_RATECARD_ERROR:
    return {
      ...state,
      isLoading: false,
      error: {
        on: true,
        message: 'Could not fetch ratecard'
      }
    };

  default:
    return state;
  }
};
