import { SHOW_ERROR } from '../constants/errorTypes';

import {
  FETCH_BANK_DETAILS,
  FETCH_BANK_DETAILS_ERROR,
  FETCH_BANK_DETAILS_SUCCESS,
  SEND_BANK_DETAILS,
  SEND_BANK_DETAILS_ERROR,
  SEND_BANK_DETAILS_SUCCESS,
  FETCH_DOCUMENT,
  FETCH_DOCUMENT_ERROR,
  FETCH_DOCUMENT_SUCCESS
} from '../constants/bank-details-types';

import api from '../helpers/api';

export const fetchBankDetails = doctorId => {
  return dispatch => {
    dispatch({
      type: FETCH_BANK_DETAILS
    });

    return api
      .fetchBankDetails(doctorId)
      .then(({ data }) => {
        if (data.data) {
          const response = data.data;
          const obj = {
            account_holder_name: response.account_holder_name,
            pan_number: response.pan_number,
            bank_account_number: response.bank_account_number,
            confirm_bank_account_number: response.bank_account_number,
            account_type: response.account_type,
            ifsc_code: response.ifsc_code
          };
          dispatch({
            type: FETCH_BANK_DETAILS_SUCCESS,
            data: obj
          });
        } else {
          dispatch({
            type: FETCH_BANK_DETAILS_SUCCESS,
            data: {
              account_holder_name: '',
              pan_number: '',
              bank_account_number: '',
              confirm_bank_account_number: '',
              account_type: '',
              ifsc_code: ''
            }
          });
        }
      })
      .catch(error => {
        dispatch({
          type: FETCH_BANK_DETAILS_ERROR,
          error
        });
        throw new Error({ message: error.message });
      });
  };
};

export const fetchDocument = doctorId => {
  return dispatch => {
    dispatch({
      type: FETCH_DOCUMENT
    });

    return api
      .fetchDocument(doctorId)
      .then(({ data }) => {
        dispatch({
          type: FETCH_DOCUMENT_SUCCESS,
          data
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_DOCUMENT_ERROR,
          error
        });
        throw new Error({ message: error.message });
      });
  };
};

export const sendBankDetails = payload => {
  return dispatch => {
    dispatch({
      type: SEND_BANK_DETAILS
    });

    return api
      .sendBankDetails(payload)
      .then(({ data }) => {
        dispatch({
          type: SEND_BANK_DETAILS_SUCCESS,
          data
        });
      })
      .catch(error => {
        dispatch({
          type: SEND_BANK_DETAILS_ERROR,
          error
        });
        dispatch({ type: SHOW_ERROR });
        throw new Error({ message: error.message });
      });
  };
};
