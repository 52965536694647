import {
  FETCH_BANK_DETAILS,
  FETCH_BANK_DETAILS_ERROR,
  FETCH_BANK_DETAILS_SUCCESS,
  FETCH_DOCUMENT,
  FETCH_DOCUMENT_ERROR,
  FETCH_DOCUMENT_SUCCESS
} from '../constants/bank-details-types';

const initialState = {
  isLoading: false,
  data: {},
  fileUrl: '',
  error: {
    on: false,
    message: 'Something went wrong'
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
  case FETCH_BANK_DETAILS:
    return { ...state, isLoading: true, fileUrl: '', data: {} };
  case FETCH_DOCUMENT:
    return { ...state, isLoading: true, fileUrl: '' };

  case FETCH_BANK_DETAILS_SUCCESS:
    return {
      ...state,
      isLoading: false,
      data: action.data
    };

  case FETCH_DOCUMENT_SUCCESS:
    return {
      ...state,
      isLoading: false,
      fileUrl: action.data.file_url
    };

  case FETCH_BANK_DETAILS_ERROR:
  case FETCH_DOCUMENT_ERROR:
    return {
      ...state,
      error: {
        on: true,
        message: action.error
      }
    };

  default:
    return state;
  }
};
