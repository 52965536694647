import React from 'react';
import PropTypes from 'prop-types';
import { Button, Confirm } from 'semantic-ui-react';

function ConfirmModal({
  cancelButton,
  className,
  confirmButton,
  content,
  header,
  onCancel,
  onConfirm,
  open,
  size
}) {
  return (
    <Confirm
      cancelButton={<Button basic>{cancelButton}</Button>}
      className={className}
      confirmButton={
        <Button primary={false} color="yellow">
          {confirmButton}
        </Button>
      }
      content={content}
      header={header}
      onCancel={onCancel}
      onConfirm={onConfirm}
      open={open}
      size={size || 'large'}
    />
  );
}

ConfirmModal.propTypes = {
  cancelButton: PropTypes.string,
  className: PropTypes.string,
  confirmButton: PropTypes.string,
  content: PropTypes.string,
  header: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  size: PropTypes.string
};

export default ConfirmModal;
