import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Label, Grid, Modal, Icon, Divider, Button } from 'semantic-ui-react';
import { DoctorInfo, ProcedureRow } from '.';

function ViewRatecardModal({
  contractFileURL,
  open,
  handleClose,
  ratecard,
  contract_status,
  network_type,
  modification_time,
  ...props
}) {
  const procedures = ratecard.procedures;
  return (
    <Modal
      className="ratecard-modal"
      open={open}
      onClose={handleClose}
      style={{ marginTop: 0 }} // Fixes issue with Modal positioning
      closeIcon={<Icon name="close" onClick={handleClose} />}
    >
      <Modal.Header>Rate Card</Modal.Header>
      <Modal.Content scrolling>
        <Modal.Description>
          <Grid className="modal-grid">
            <Grid.Row columns={2} className="modal-row">
              <Grid.Column width={3}>
                <span className="index">1</span>
                <span className="header">Clinic Contract</span>
              </Grid.Column>
              <Grid.Column width={12}>
                <a
                  href={contractFileURL}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Button size="small" compact color="yellow">
                    View
                  </Button>
                </a>
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row className="modal-row">
              <Grid.Column width={3}>
                <span className="index">2</span>
                <span className="header">Rate Card</span>
              </Grid.Column>
              <Grid.Column width={16}>
                <DoctorInfo className="doctor-info" {...props} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="procedure-header">
              <Grid.Column width={12}>PROCEDURE NAME</Grid.Column>
              <Grid.Column width={4}>COST (₹)</Grid.Column>
            </Grid.Row>
            {procedures.length > 0 &&
              procedures.map((procedure, index) => (
                <ProcedureRow
                  {...procedure}
                  key={`${procedure.master_procedure_id}-${index}`}
                  readOnly={true}
                />
              ))}
          </Grid>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        {contract_status.toUpperCase() === 'PENDING' ? (
          <Label color="yellow" size="mini" image>
            PENDING
            <Label.Detail>
              {moment(modification_time).format('DD/MM/YYYY')}
            </Label.Detail>
          </Label>
        ) : network_type.toUpperCase() === 'CASHLESS' ? (
          <Fragment>
            <Label color="green" size="mini" image>
              CASHLESS
              <Label.Detail>
                {moment(modification_time).format('DD/MM/YYYY')}
              </Label.Detail>
            </Label>
          </Fragment>
        ) : null}
      </Modal.Actions>
    </Modal>
  );
}

ViewRatecardModal.propTypes = {
  contractFileURL: PropTypes.string.isRequired,
  contract_status: PropTypes.string.isRequired,
  modification_time: PropTypes.string.isRequired,
  network_type: PropTypes.string.isRequired,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  ratecard: PropTypes.object.isRequired
};

export default connect(state => ({
  contractFileURL: state.contract.file_url,
  ratecard: state.ratecard.data.ratecard
}))(ViewRatecardModal);
