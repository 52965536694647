import React from 'react';
import PropTypes from 'prop-types';
import { Rating } from 'semantic-ui-react';

function RatingInput({ input }) {
  return (
    <span>
      <p className="rating-text">Minimum Recommendation: <b>{input.value || 0}</b></p>
      <Rating
        icon="star"
        maxRating={10}
        rating={input.value / 10}
        onRate={(event, data) => {
          const rating = data.rating * 10;
          input.onChange(rating);
        }}
      />
    </span>
  );
}

RatingInput.propTypes = {
  input: PropTypes.object.isRequired
};

export default RatingInput;
