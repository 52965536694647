import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { DoctorCard } from '.';
import { toggleDoctor } from '../../actions/doctors';

function CardContainer({ doctors, filters, reload, toggleDoctor, type }) {
  const { values: { offset = 0 } = {} } = filters;

  return (
    <Grid stackable columns={2} className="l-card-container">
      {!doctors.isLoading && doctors.count ? (
        <Grid.Row className="show-count">
          <Grid.Column>
            <span className="count">
              Showing {offset + 1} - {offset + doctors.data.length} of{' '}
              {doctors.count} results ...
            </span>
          </Grid.Column>
          {type !== 'MASTER' && (
            <Grid.Column textAlign="right">
              <span className="status-count">
                <span className="green label">Cashless:</span>
                {doctors.approved_count || 0}
              </span>
              <span className="status-count">
                <span className="yellow label">Pending:</span>
                {doctors.pending_count || 0}
              </span>
              <span className="status-count">
                <span className="red label">Rejected:</span>
                {doctors.rejected_count || 0}
              </span>
            </Grid.Column>
          )}
        </Grid.Row>
      ) : null}
      {doctors.data.length ? (
        doctors.data.map((doctor, index) => (
          <DoctorCard
            key={`${doctor.doctor_id}-${index}`}
            {...doctor}
            reload={reload}
            onSelectDoctor={toggleDoctor}
          />
        ))
      ) : (
        <Grid.Row centered columns={1}>
          <h3>No Data</h3>
        </Grid.Row>
      )}
    </Grid>
  );
}

CardContainer.propTypes = {
  doctors: PropTypes.object,
  filters: PropTypes.object,
  reload: PropTypes.func.isRequired,
  toggleDoctor: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

export default connect(
  state => ({
    doctors: state.doctor,
    filters: state.form.filter,
    type: state.masterData.type
  }),
  {
    toggleDoctor
  }
)(CardContainer);
