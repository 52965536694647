export const isDirty = (field, values = {}, previousValues = {}) => {
  if (!values.hasOwnProperty(field)) {
    return false;
  }
  if (values.hasOwnProperty(field) && !previousValues.hasOwnProperty(field)) {
    return true;
  }
  if (values[field] !== previousValues[field]) {
    return true;
  }
};

export const getHost = subdomain => {
  let baseUrl = window.location.origin;
  if (subdomain) {
    baseUrl = baseUrl.replace('insurance', subdomain);
  }

  return baseUrl;
};

export const downloader = (data, filename) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};
