export const FETCH_RATECARD = 'FETCH_RATECARD';
export const FETCH_RATECARD_SUCCESS = 'FETCH_RATECARD_SUCCESS';
export const FETCH_RATECARD_ERROR = 'FETCH_RATECARD_ERROR';

export const FETCH_PROCEDURES = 'FETCH_PROCEDURES';
export const FETCH_PROCEDURES_SUCCESS = 'FETCH_PROCEDURES_SUCCESS';
export const FETCH_PROCEDURES_ERROR = 'FETCH_PROCEDURES_ERROR';

export const ADD_PROCEDURE = 'ADD_PROCEDURE';
export const REMOVE_PROCEDURE = 'REMOVE_PROCEDURE';
export const CLEAR_PROCEDURES = 'CLEAR_PROCEDURES';
export const ADD_PROCEDURE_COST = 'ADD_PROCEDURE_COST';

export const SEND_DOCUMENTS = 'SEND_DOCUMENTS';
export const SEND_DOCUMENTS_SUCCESS = 'SEND_DOCUMENTS_SUCCESS';
export const SEND_DOCUMENTS_ERROR = 'SEND_DOCUMENTS_ERROR';

export const RESET_DOCUMENTS = 'RESET_DOCUMENTS';
