import { SHOW_ERROR } from '../constants/errorTypes';
import {
  ADD_PROCEDURE,
  ADD_PROCEDURE_COST,
  CLEAR_PROCEDURES,
  FETCH_PROCEDURES,
  FETCH_PROCEDURES_ERROR,
  FETCH_PROCEDURES_SUCCESS,
  REMOVE_PROCEDURE,
  RESET_DOCUMENTS,
  SEND_DOCUMENTS,
  SEND_DOCUMENTS_ERROR,
  SEND_DOCUMENTS_SUCCESS
} from '../constants/ratecardTypes';
import api from '../helpers/api';

export const fetchMasterProcedures = payorId => {
  return dispatch => {
    dispatch({
      type: FETCH_PROCEDURES
    });

    return api
      .getMasterProcedures(payorId)
      .then(({ data }) => {
        dispatch({
          type: FETCH_PROCEDURES_SUCCESS,
          data
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_PROCEDURES_ERROR,
          error: error.message
        });
        dispatch({ type: SHOW_ERROR });
        throw new Error({ message: error.message });
      });
  };
};

export const addProcedure = procedure => {
  return dispatch => {
    dispatch({
      type: ADD_PROCEDURE,
      procedure
    });
  };
};

export const removeProcedure = procedureId => {
  return dispatch => {
    dispatch({
      type: REMOVE_PROCEDURE,
      procedureId
    });
  };
};

export const clearProcedures = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_PROCEDURES
    });
  };
};

export const addProcedureCost = payload => {
  return dispatch => {
    dispatch({
      type: ADD_PROCEDURE_COST,
      payload
    });
  };
};

export const sendDocuments = payload => {
  return dispatch => {
    dispatch({
      type: SEND_DOCUMENTS
    });

    return api
      .sendDocuments(payload)
      .then(({ data }) => {
        dispatch({
          type: SEND_DOCUMENTS_SUCCESS,
          data
        });
      })
      .catch(error => {
        dispatch({
          type: SEND_DOCUMENTS_ERROR,
          error: error.message
        });
        throw new Error({ message: error.message });
      });
  };
};

export const resetDocuments = () => {
  return dispatch => {
    dispatch({
      type: RESET_DOCUMENTS
    });
  };
};
