import { SHOW_ERROR, RESET_ERROR } from '../constants/errorTypes';

const INITIAL_STATE = {
  on: false,
  message: ''
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case SHOW_ERROR:
    return {
      ...INITIAL_STATE,
      on: true,
      message: action.message
    };
  case RESET_ERROR:
    return INITIAL_STATE;
  default:
    return state;
  }
};
