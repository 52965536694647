import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Pagination } from 'semantic-ui-react';

const OFFSET = 50;

function CustomPagination({ input, count, meta, ...rest }) {
  return (
    <Pagination
      size="mini"
      activePage={input.value / OFFSET + 1 || 1}
      totalPages={count}
      onPageChange={(e, d) => {
        const offset = (d.activePage - 1) * OFFSET;
        input.onChange(offset);
      }}
      {...rest}
    />
  );
}

CustomPagination.propTypes = {
  input: PropTypes.object.isRequired,
  count: PropTypes.number,
  meta: PropTypes.object.isRequired
};

function FooterPagination({ count }) {
  return (
    <Field
      name="offset"
      component={CustomPagination}
      count={Math.ceil(count / OFFSET)}
    />
  );
}

FooterPagination.propTypes = {
  count: PropTypes.number.isRequired
};

export default reduxForm({
  form: 'filter',
  destroyOnUnmount: false
})(FooterPagination);
