import {
  FETCH_DOCTORS,
  FETCH_DOCTORS_SUCCESS,
  FETCH_DOCTORS_ERROR,
  FETCH_DOCTORS_COUNT_ERROR,
  FETCH_DOCTORS_COUNT_SUCCESS,
  SET_INITIAL_DOCTOR_STATE
} from '../constants/doctorTypes';

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  count: 0,
  approved_count: 0,
  rejected_count: 0,
  pending_count: 0,
  error: {
    on: false,
    message: ''
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case FETCH_DOCTORS:
    return {
      ...state,
      isLoading: true
    };
  case FETCH_DOCTORS_SUCCESS:
    return {
      ...state,
      isLoading: false,
      data: action.data.doctors,
      count: action.data.count
    };

  case FETCH_DOCTORS_ERROR:
  case FETCH_DOCTORS_COUNT_ERROR:
    return {
      ...INITIAL_STATE,
      error: {
        on: true,
        error: action.error
      }
    };

  case FETCH_DOCTORS_COUNT_SUCCESS:
    return {
      ...state,
      approved_count: action.data.approved_count,
      rejected_count: action.data.rejected_count,
      pending_count: action.data.pending_count
    };

  case SET_INITIAL_DOCTOR_STATE:
    return INITIAL_STATE;
  default:
    return state;
  }
};
