import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { Component, Fragment } from 'react';
import { Button, Grid, Icon, Image, List } from 'semantic-ui-react';
import illustration from '../../assets/images/illustration.png';
import { SuccessModal } from '../../components';
import {
  getPracticeDetailsFromToken,
  sendInterest
} from '../../helpers/lead-gen-api';

class PopupPage extends Component {
  constructor(props) {
    super(props);
    const { location: { search } = {} } = props;
    const { source, token, email } = qs.parse(search);
    this.state = {
      token,
      source,
      email,
      openModal: false,
      isLoading: false
    };

    this.closeModal = this.closeModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { history, match } = this.props;
    const { token, source, email } = this.state;
    const payload = {
      step: 1,
      interested: true,
      source
    };

    if (email) {
      payload.email = email;
    }

    this.setState({
      isLoading: true
    });

    Promise.all([
      sendInterest(token, payload),
      getPracticeDetailsFromToken(token)
    ])
      .then(([, second]) => second.data)
      .then(data => {
        this.setState({
          isLoading: false
        });

        history.push(`${match.url}/ratecard`, { data, email, source, token });
      })
      .catch(() => {
        this.setState({
          openModal: true,
          isLoading: false
        });
      });
  }

  closeModal() {
    this.setState({
      openModal: false
    });
  }

  renderHeader() {
    return (
      <Fragment>
        <span className="main-header">
          <div>Introducing India’s first and largest</div>
          <div>
            <b>OPD Insurance Network</b>
          </div>
        </span>
        <p className="sign-up-text">Sign up for free!</p>
      </Fragment>
    );
  }

  renderList() {
    const benefitsList = [
      'Boost patient footfall',
      'Increase your clinic visibility',
      'Instant approval for all claims',
      'Guaranteed online settlements'
    ];

    return (
      <List>
        {benefitsList.map((benefitsText, index) => (
          <List.Item key={index}>
            <Icon name="circle" size="small" color="orange" />
            <List.Content>{benefitsText}</List.Content>
          </List.Item>
        ))}
      </List>
    );
  }

  render() {
    return (
      <Grid padded>
        <Grid.Row columns={2} className="intro-row">
          <Grid.Column width={10} className="intro-column">
            {this.renderHeader()}
            {this.renderList()}
          </Grid.Column>
          <Grid.Column width={6}>
            <Image
              size="medium"
              centered
              src={illustration}
              alt="Illustration"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1} className="status-row">
          <Grid.Column width={12} className="status-column">
            <Grid padded>
              <Grid.Row columns={3} verticalAlign="middle">
                <Grid.Column
                  width={5}
                  textAlign="right"
                  className="details-column"
                >
                  <Grid>
                    <Grid.Row verticalAlign="middle">
                      <Grid.Column textAlign="right" width={7}>
                        <span className="status-number">3k+</span>
                      </Grid.Column>
                      <Grid.Column width={9} textAlign="left">
                        <span className="status-doctors">
                          DOCTORS <br /> in <b>Top-7</b> cities
                        </span>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
                <Grid.Column width={6} className="subtext">
                  Already LIVE with 3000+ doctors across top cities in India and
                  growing fast
                </Grid.Column>
                <Grid.Column width={5} textAlign="right">
                  <Button
                    loading={this.state.isLoading}
                    color="yellow"
                    size="small"
                    onClick={this.handleClick}
                  >
                    I am interested
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column className="trusted-partners-column" width={12}>
            Trusted partners like Aegis, Frontline, MaxCure, Vita Family and
            many others
          </Grid.Column>
        </Grid.Row>
        {this.state.openModal && (
          <SuccessModal
            actionText="OKAY"
            handleClick={this.closeModal}
            header="Something went wrong"
            message="Please try again."
            open={this.state.openModal}
            size="mini"
          />
        )}
      </Grid>
    );
  }
}

PopupPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default PopupPage;
