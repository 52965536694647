import api from '../helpers/api';
import {
  UPLOAD_CONTRACT,
  UPLOAD_CONTRACT_SUCCESS,
  UPLOAD_CONTRACT_ERROR,
  FETCH_CONTRACT,
  FETCH_CONTRACT_SUCCESS,
  FETCH_CONTRACT_ERROR,
  RESET_CONTRACT
} from '../constants/contractTypes';

export const uploadContract = (doctorPolicyId, fileToUpload) => {
  return dispatch => {
    dispatch({
      type: UPLOAD_CONTRACT
    });

    return api
      .uploadContract(doctorPolicyId, fileToUpload)
      .then(({ data }) => {
        dispatch({
          type: UPLOAD_CONTRACT_SUCCESS,
          data
        });
      })
      .catch(error => {
        dispatch({
          type: UPLOAD_CONTRACT_ERROR,
          error: error.message
        });
        throw new Error('Unable to upload contract');
      });
  };
};

export const resetContract = () => {
  return dispatch => {
    dispatch({
      type: RESET_CONTRACT
    });
  };
};

export const fetchContractLink = contractURL => {
  return dispatch => {
    dispatch({
      type: FETCH_CONTRACT
    });

    return api
      .fetchContractLink(contractURL)
      .then(({ data }) => {
        dispatch({
          type: FETCH_CONTRACT_SUCCESS,
          data
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_CONTRACT_ERROR,
          error: error.message
        });
        throw new Error({ message: error.message });
      });
  };
};
