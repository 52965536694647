import PropTypes from 'prop-types';
import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { ClinicInfo, RatecardTable } from '.';
import TermsDoc from './markdown/terms.md';

function Terms({ open, handleClose, ratecards, practice }) {
  return (
    <Modal
      size="small"
      open={open}
      onClose={handleClose}
      style={{ marginTop: '0px' }}
    >
      <Modal.Header>Terms and Conditions</Modal.Header>
      <Modal.Content scrolling>
        <ClinicInfo practice={practice} />
        <TermsDoc />
        <RatecardTable ratecards={ratecards} />
        <br />
        <span>
          YOU HAVE READ THESE TERMS AND CONDITIONS AND AGREE TO ALL OF THE
          PROVISIONS CONTAINED ABOVE
        </span>
      </Modal.Content>
      <Modal.Actions>
        <Button color="yellow" content="Done" onClick={handleClose} />
      </Modal.Actions>
    </Modal>
  );
}

Terms.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  ratecards: PropTypes.array.isRequired,
  practice: PropTypes.object.isRequired
};

export default Terms;
