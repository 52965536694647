import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import contractReducer from './contract';
import doctorReducer from './doctors';
import documentsReducer from './documents';
import downloadReducer from './download';
import errorReducer from './error';
import masterDataReducer from './masterData';
import proceduresReducer from './procedures';
import ratecardReducer from './ratecard';
import selectedDoctorsReducer from './selectedDoctors';
import userReducer from './user';
import bankDetailsReducer from './bank-details';

export default combineReducers({
  contract: contractReducer,
  doctor: doctorReducer,
  documents: documentsReducer,
  download: downloadReducer,
  error: errorReducer,
  form: formReducer,
  masterData: masterDataReducer,
  procedures: proceduresReducer,
  ratecard: ratecardReducer,
  selectedDoctors: selectedDoctorsReducer,
  user: userReducer,
  bank: bankDetailsReducer
});
