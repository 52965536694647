export const GET_MASTER_SPECIALITIES = 'GET_MASTER_SPECIALITIES';
export const GET_MASTER_SPECIALITIES_ERROR = 'GET_MASTER_SPECIALITIES_ERROR';

export const GET_MASTER_CITIES = 'GET_MASTER_CITIES';
export const GET_MASTER_CITIES_ERROR = 'GET_MASTER_CITIES_ERROR';

export const GET_LOCALITY = 'GET_LOCALITY';
export const GET_LOCALITY_ERROR = 'GET_LOCALITY_ERROR';

export const GET_PAYOR_PLANS = 'GET_PAYOR_PLANS';
export const GET_PAYOR_PLANS_ERROR = 'GET_PAYOR_PLANS_ERROR';

export const SET_TAB_TYPE = 'SET_TAB_TYPE';

export const RESET_LOCALITIES = 'RESET_LOCALITIES';

export const SET_BANK_DETAILS_ENABLED = 'SET_BANK_DETAILS_ENABLED';
