import {
  DOWNLOAD_SHORTLISTED_DOCTORS,
  DOWNLOAD_SHORTLISTED_DOCTORS_ERROR,
  DOWNLOAD_SHORTLISTED_DOCTORS_SUCCESS,
  DOWNLOAD_QRCODE,
  DOWNLOAD_QRCODE_ERROR,
  DOWNLOAD_QRCODE_SUCCESS
} from '../constants/doctorTypes';

const INITIAL_STATE = {
  isLoading: false,
  isQRCodeLoading: false,
  doctorId: null,
  data: {},
  error: {
    on: false,
    message: ''
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case DOWNLOAD_SHORTLISTED_DOCTORS:
    return {
      ...INITIAL_STATE,
      isLoading: true
    };

  case DOWNLOAD_SHORTLISTED_DOCTORS_SUCCESS:
    return {
      ...state,
      isLoading: false,
      data: action.data
    };

  case DOWNLOAD_SHORTLISTED_DOCTORS_ERROR:
    return {
      ...state,
      isLoading: false,
      error: {
        on: true,
        message: 'Unable to download shortlisted doctors'
      }
    };

  case DOWNLOAD_QRCODE:
    return {
      ...INITIAL_STATE,
      isQRCodeLoading: true,
      doctorId: action.doctorId
    };

  case DOWNLOAD_QRCODE_SUCCESS:
    return {
      ...state,
      isQRCodeLoading: false,
      data: action.data
    };

  case DOWNLOAD_QRCODE_ERROR:
    return {
      ...state,
      isQRCodeLoading: false,
      error: {
        on: true,
        message: 'Unable to download QR code'
      }
    };
  default:
    return state;
  }
};
