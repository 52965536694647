const routes = {
  GET_USERS: '/api/v1/users',
  GET_USER_DETAILS: '/api/v1/users/session',
  GET_ALL_LOCALITIES: '/api/v1/practices/localities',
  GET_ALL_PAYORS: '/api/v1/payors',
  GET_ALL_CITIES: '/api/v1/practices/cities',
  GET_ALL_SPECIALITIES: '/api/v1/doctors/specialities',
  DOCTORS: '/api/v1/doctors',
  GET_PAYOR_PLANS: '/api/v1/payors',
  GET_RATECARD: 'api/v1/ratecards',
  DOCTOR_POLICIES: 'api/v1/doctorspolicies',
  FETCH_CONTRACT_URL: 'api/v1/practices/viewcontract',
  PRACTICES: '/api/v1/practices'
};

export default routes;
