export const FETCH_BANK_DETAILS = 'FETCH_BANK_DETAILS';
export const FETCH_BANK_DETAILS_SUCCESS = 'FETCH_BANK_DETAILS_SUCCESS';
export const FETCH_BANK_DETAILS_ERROR = 'FETCH_BANK_DETAILS_ERROR';

export const SEND_BANK_DETAILS = 'SEND_BANK_DETAILS';
export const SEND_BANK_DETAILS_SUCCESS = 'SEND_BANK_DETAILS_SUCCESS';
export const SEND_BANK_DETAILS_ERROR = 'SEND_BANK_DETAILS_ERROR';

export const FETCH_DOCUMENT = 'FETCH_DOCUMENT';
export const FETCH_DOCUMENT_SUCCESS = 'FETCH_DOCUMENT_SUCCESS';
export const FETCH_DOCUMENT_ERROR = 'FETCH_DOCUMENT_ERROR';
