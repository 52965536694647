export const FETCH_DOCTORS = 'FETCH_DOCTORS';
export const FETCH_DOCTORS_SUCCESS = 'FETCH_DOCTORS_SUCCESS';
export const FETCH_DOCTORS_ERROR = 'FETCH_DOCTORS_ERROR';

export const SET_INITIAL_DOCTOR_STATE = 'SET_INITIAL_DOCTOR_STATE';

export const SHORTLIST_DOCTORS = 'SHORTLIST_DOCTORS';
export const SHORTLIST_DOCTORS_SUCCESS = 'SHORTLIST_DOCTORS_SUCCESS';
export const SHORTLIST_DOCTORS_ERROR = 'SHORTLIST_DOCTORS_ERROR';

export const UNLIST_DOCTORS = 'UNLIST_DOCTORS';
export const UNLIST_DOCTORS_SUCCESS = 'UNLIST_DOCTORS_SUCCESS';
export const UNLIST_DOCTORS_ERROR = 'UNLIST_DOCTORS_ERROR';

export const UNMARK_CASHLESS = 'UNMARK_CASHLESS';
export const UNMARK_CASHLESS_SUCCESS = 'UNMARK_CASHLESS_SUCCESS';
export const UNMARK_CASHLESS_ERROR = 'UNMARK_CASHLESS_ERROR';

export const TOGGLE_DOCTOR = 'TOGGLE_DOCTOR';

export const UNSELECT_ALL_DOCTORS = 'UNSELECT_ALL_DOCTORS';

export const FETCH_DOCTORS_COUNT = 'FETCH_DOCTORS_COUNT';
export const FETCH_DOCTORS_COUNT_SUCCESS = 'FETCH_DOCTORS_COUNT_SUCCESS';
export const FETCH_DOCTORS_COUNT_ERROR = 'FETCH_DOCTORS_COUNT_ERROR';

export const DOWNLOAD_SHORTLISTED_DOCTORS = 'DOWNLOAD_SHORTLISTED_DOCTORS';
export const DOWNLOAD_SHORTLISTED_DOCTORS_SUCCESS =
  'DOWNLOAD_SHORTLISTED_DOCTORS_SUCCESS';
export const DOWNLOAD_SHORTLISTED_DOCTORS_ERROR =
  'DOWNLOAD_SHORTLISTED_DOCTORS_ERROR';

export const DOWNLOAD_QRCODE = 'DOWNLOAD_QRCODE';
export const DOWNLOAD_QRCODE_SUCCESS = 'DOWNLOAD_QRCODE_SUCCESS';
export const DOWNLOAD_QRCODE_ERROR = 'DOWNLOAD_QRCODE_ERROR';
