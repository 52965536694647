import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { MenuItem, Radio } from 'semantic-ui-react';

function CashlessToggle({ input }) {
  const custom = {
    checked: input.checked,
    onChange: (event, data) => input.onChange(data.checked)
  };
  return <Radio toggle {...custom} />;
}

CashlessToggle.propTypes = {
  input: PropTypes.object.isRequired
};

function ShortlistMenuItem({ name, active, index, content, onClick }) {
  return (
    <MenuItem active={active} index={index} content={content} onClick={onClick}>
      <span>{name}</span>
      {active && (
        <span className="cashless-toggle">
          <span className="cashless-label">Show Cashless Only</span>
          <Field name="cashless" component={CashlessToggle} />
        </span>
      )}
    </MenuItem>
  );
}

ShortlistMenuItem.propTypes = {
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
  index: PropTypes.number,
  content: PropTypes.string,
  onClick: PropTypes.func
};

export default reduxForm({
  form: 'filter'
})(ShortlistMenuItem);
