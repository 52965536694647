import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

import reducers from '../reducers';

const middleware = [thunk];
const initialState = {};
const loggerMiddleware = createLogger({
  predicate: () => process.env.NODE_ENV === 'development'
});
middleware.push(loggerMiddleware);

export default createStore(
  reducers,
  initialState,
  compose(
    applyMiddleware(...middleware),
    window.devToolsExtension ? window.devToolsExtension() : f => f
  )
);
