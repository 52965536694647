import { TOGGLE_DOCTOR, UNSELECT_ALL_DOCTORS } from '../constants/doctorTypes';

const INITIAL_STATE = [];

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case TOGGLE_DOCTOR:
    if (state.indexOf(action.data) > -1) {
      return [...state.filter(item => item !== action.data)];
    } else {
      return [...state, action.data];
    }
  case UNSELECT_ALL_DOCTORS:
    return [];

  default:
    return state;
  }
};
