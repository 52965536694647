import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';

function SuccessModal({
  actionText,
  color,
  handleClick,
  header,
  message,
  open,
  size
}) {
  return (
    <Modal size={size || 'small'} open={open}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <p>{message}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color={color || 'yellow'}
          content={actionText}
          onClick={handleClick}
        />
      </Modal.Actions>
    </Modal>
  );
}

SuccessModal.propTypes = {
  actionText: PropTypes.string.isRequired,
  color: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  size: PropTypes.string
};

export default SuccessModal;
