import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Grid, Label } from 'semantic-ui-react';

function DoctorInfo({
  bank_status,
  className,
  doctor_name,
  policies,
  policy_id,
  qualifications,
  speciality,
  validity
}) {
  let plan_name;
  if (policy_id) {
    const policy = policies.filter(policy => policy.id === policy_id)[0];
    if (policy) {
      plan_name = policy.name;
    }
  }
  return (
    <Grid columns={4} className={className}>
      <Grid.Column>
        <Grid.Row className="title name">{doctor_name || 'NA'}</Grid.Row>
        <Grid.Row className="grey-header wrap-text" title={speciality || 'NA'}>
          {`${qualifications || 'NA'} | ${speciality || 'NA'}`}
        </Grid.Row>
      </Grid.Column>
      <Grid.Column>
        <Grid.Row className="grey-header title">PLAN NAME</Grid.Row>
        <Grid.Row>{plan_name || 'NA'}</Grid.Row>
      </Grid.Column>
      <Grid.Column>
        <Grid.Row className="grey-header title">VALIDITY</Grid.Row>
        <Grid.Row>
          {(validity && moment(validity).format('Do MMMM YY')) || 'NA'}
        </Grid.Row>
      </Grid.Column>
      <Grid.Column>
        <Grid.Row className="grey-header title">BANK STATUS</Grid.Row>
        <Grid.Row>
          {bank_status === 1 ? (
            <Label color="green">ACTIVATED</Label>
          ) : (
            <Label color="red">NOT ACTIVATED</Label>
          )}
        </Grid.Row>
      </Grid.Column>
    </Grid>
  );
}

DoctorInfo.propTypes = {
  bank_status: PropTypes.number,
  className: PropTypes.string,
  doctor_name: PropTypes.string,
  policies: PropTypes.array.isRequired,
  policy_id: PropTypes.number,
  qualifications: PropTypes.string,
  speciality: PropTypes.string,
  validity: PropTypes.string
};

export default connect(state => ({
  policies: state.masterData.plans
}))(DoctorInfo);
