import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Dropzone from 'react-dropzone';
import { Button } from 'semantic-ui-react';

function FileUpload({
  accept,
  multiple,
  handleDrop,
  input,
  isLoading,
  contractKey,
  placeholder,
  meta: { touched, error }
}) {
  const { value: [{ name: fileName } = {}] = [] } = input;
  return (
    <Fragment>
      <Dropzone
        className="dropzone"
        name={input.name}
        accept={accept}
        multiple={multiple || false}
        style={{}}
        onDrop={fileToUpload => {
          if (handleDrop) {
            handleDrop(fileToUpload);
          }
          return input.onChange(fileToUpload);
        }}
      >
        <Button
          disabled={isLoading || false}
          loading={isLoading || false}
          className="orange-btn upload-btn"
          size="small"
          type="button"
          compact
          color="yellow"
        >
          {fileName || placeholder || 'Upload'}
        </Button>
      </Dropzone>
      {!isLoading && contractKey ? (
        <span className="upload-success">Document uploaded successfully</span>
      ) : null}
      {touched && error ? (
        <span className="upload-success">Required</span>
      ) : null}
    </Fragment>
  );
}

FileUpload.propTypes = {
  contractKey: PropTypes.string,
  input: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  handleDrop: PropTypes.func,
  multiple: PropTypes.bool,
  accept: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired
};

export default FileUpload;
