import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Menu } from 'semantic-ui-react';
import { FooterPagination } from '..';
import {
  downloadShortlistedDoctors,
  shortlistDoctors,
  toggleDoctor,
  unlistDoctors,
  unselectAllDoctors
} from '../../actions/doctors';
import messages from '../../constants/messages';
import { downloader } from '../../helpers/utils';

function FooterMenu({
  doctors,
  downloadLoading,
  downloadShortlistedDoctors,
  filters,
  reload,
  selectedDoctors,
  shortlistDoctors,
  toggleDoctor,
  type,
  unlistDoctors,
  unselectAllDoctors
}) {
  function toggleAllDoctors(doctors) {
    if (selectedDoctors.length !== doctors.length) unselectAllDoctors();
    return doctors.map(doctor => toggleDoctor(doctor.doctor_id));
  }

  function removeReimbursementDoctors(doctorIds) {
    const selectedDoctorsWithData = doctors.data.filter(
      doctor => doctorIds.indexOf(doctor.doctor_id) > -1
    );
    if (
      selectedDoctorsWithData.some(doctor => doctor.network_type === 'CASHLESS')
    ) {
      const answer = confirm(messages.removeShortlistedDoctorsText);
      if (answer) {
        const doctorIdsToBeRemoved = selectedDoctorsWithData
          .filter(doctor => doctor.network_type !== 'CASHLESS')
          .map(doctor => doctor.doctor_id);
        if (doctorIdsToBeRemoved.length) {
          return unlistDoctors(doctorIdsToBeRemoved);
        }
      } else {
        return Promise.resolve({ persistSelection: true });
      }
    } else {
      return unlistDoctors(doctorIds);
    }
  }

  const downloadDoctors = () => {
    return downloadShortlistedDoctors(filters.values, type).then(data =>
      downloader(data, 'doctors.csv')
    );
  };

  if (doctors.data.length <= 0) return null;
  return (
    <Menu size="mini" borderless fluid fixed="bottom">
      <Menu.Item>
        <FooterPagination count={doctors.count} />
      </Menu.Item>
      <Menu.Menu position="right">
        {type !== 'MASTER' && (
          <Menu.Item>
            <Button
              loading={downloadLoading}
              onClick={downloadDoctors}
              color="yellow"
            >
              Download
            </Button>
          </Menu.Item>
        )}
        <Menu.Item>
          <Button
            basic
            color="yellow"
            onClick={() => toggleAllDoctors(doctors.data)}
          >
            {selectedDoctors.length === doctors.data.length
              ? 'Clear Selection'
              : 'Select All'}
          </Button>
        </Menu.Item>
        {type === 'MASTER' ? (
          <Menu.Item>
            <Button
              disabled={selectedDoctors.length < 1}
              onClick={() =>
                shortlistDoctors(selectedDoctors)
                  .then(unselectAllDoctors)
                  .then(reload)
              }
              color="yellow"
            >
              Add
            </Button>
          </Menu.Item>
        ) : (
          <Menu.Item>
            <Button
              disabled={selectedDoctors.length < 1}
              onClick={() =>
                removeReimbursementDoctors(selectedDoctors).then(result => {
                  if (result && result.persistSelection) {
                    return;
                  } else {
                    unselectAllDoctors();
                    return reload();
                  }
                })
              }
              color="yellow"
            >
              Remove
            </Button>
          </Menu.Item>
        )}
      </Menu.Menu>
      <Menu.Item />
    </Menu>
  );
}

FooterMenu.propTypes = {
  doctors: PropTypes.object.isRequired,
  downloadLoading: PropTypes.bool.isRequired,
  downloadShortlistedDoctors: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired,
  selectedDoctors: PropTypes.array,
  shortlistDoctors: PropTypes.func.isRequired,
  toggleDoctor: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  unlistDoctors: PropTypes.func.isRequired,
  unselectAllDoctors: PropTypes.func.isRequired
};

export default connect(
  state => ({
    doctors: state.doctor,
    downloadLoading: state.download.isLoading,
    filters: state.form.filter,
    type: state.masterData.type,
    selectedDoctors: state.selectedDoctors
  }),
  {
    downloadShortlistedDoctors,
    shortlistDoctors,
    toggleDoctor,
    unlistDoctors,
    unselectAllDoctors
  }
)(FooterMenu);
