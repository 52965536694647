import {
  FETCH_CONTRACT,
  FETCH_CONTRACT_ERROR,
  FETCH_CONTRACT_SUCCESS,
  RESET_CONTRACT,
  UPLOAD_CONTRACT,
  UPLOAD_CONTRACT_ERROR,
  UPLOAD_CONTRACT_SUCCESS
} from '../constants/contractTypes';

const INITIAL_STATE = {
  isLoading: false,
  data: {},
  file_url: '',
  fetchFileError: {
    on: false,
    message: ''
  },
  error: {
    on: false,
    message: ''
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UPLOAD_CONTRACT:
    return {
      ...state,
      isLoading: true,
      error: INITIAL_STATE.error
    };

  case UPLOAD_CONTRACT_SUCCESS:
    return {
      ...state,
      isLoading: false,
      data: action.data
    };

  case UPLOAD_CONTRACT_ERROR:
    return {
      ...state,
      isLoading: false,
      error: {
        on: true,
        message: 'Could not upload contract'
      }
    };

  case RESET_CONTRACT:
    return INITIAL_STATE;

  case FETCH_CONTRACT:
    return {
      ...state,
      isLoading: true
    };

  case FETCH_CONTRACT_SUCCESS:
    return {
      ...state,
      isLoading: false,
      file_url: action.data.file_url
    };

  case FETCH_CONTRACT_ERROR:
    return {
      ...state,
      isLoading: false,
      error: {
        on: true,
        message: 'Could not fetch contract file'
      }
    };

  default:
    return state;
  }
};
