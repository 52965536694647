import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Grid,
  Header,
  Icon,
  List,
  Table
} from 'semantic-ui-react';
import { Terms as TermsModal } from '.';
import { SuccessModal } from '../../components';
import { sendInterest } from '../../helpers/lead-gen-api';
import { getHost } from '../../helpers/utils';

class RatecardPage extends Component {
  constructor(props) {
    super(props);
    const {
      location: {
        state: {
          data: {
            data: { doctors: doctorRatecards = [], practice = {} }
          },
          source,
          email,
          token
        } = {}
      } = {}
    } = props;
    const ratecards = doctorRatecards.map(doctorRatecard => ({
      ...doctorRatecard,
      checked: true
    }));

    this.state = {
      checked: false,
      token,
      source,
      email,
      ratecards,
      practice,
      openModal: false,
      openTermsModal: false,
      isAgreeBtnLoading: false,
      isCancelBtnLoading: false
    };

    this.closeModal = this.closeModal.bind(this);
    this.closeTermsModal = this.closeTermsModal.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleCheckbox = this.toggleCheckbox.bind(this);
    this.toggleRatecard = this.toggleRatecard.bind(this);
  }

  closeModal() {
    this.setState({
      openModal: false
    });
  }

  closeTermsModal() {
    this.setState({
      openTermsModal: false
    });
  }

  handleCancel() {
    const { token, source, email } = this.state;
    const payload = {
      interested: false,
      step: 2,
      source
    };

    if (email) {
      payload.email = email;
    }

    this.setState({
      isCancelBtnLoading: true
    });

    sendInterest(token, payload)
      .then(() => {
        this.setState({
          isCancelBtnLoading: false
        });
        if (window.self !== window.top) {
          window.parent.postMessage(
            { message: 'Cancel clicked on step 2' },
            getHost('ray')
          );
        }
      })
      .catch(() => {
        this.setState({
          openModal: true,
          isCancelBtnLoading: false
        });
      });
  }

  handleSubmit() {
    const { token, source, ratecards, email, practice } = this.state;
    const payload = {
      interested: true,
      step: 2,
      source,
      data: ratecards
    };

    if (email) {
      payload.email = email;
    }

    if (practice && practice.owner_name) {
      payload.owner_name = practice.owner_name;
    }

    this.setState({
      isAgreeBtnLoading: true
    });

    sendInterest(token, payload)
      .then(() => {
        this.setState({
          isAgreeBtnLoading: false
        });
        this.props.history.push(`/invite/success`, { source });
      })
      .catch(() => {
        this.setState({
          openModal: true,
          isAgreeBtnLoading: false
        });
      });
  }

  toggleCheckbox() {
    this.setState(state => ({
      checked: !state.checked
    }));
  }

  toggleRatecard(id) {
    this.setState(state => ({
      ratecards: state.ratecards.map(ratecard => {
        if (ratecard.id === id) {
          ratecard.checked = !ratecard.checked;
        }
        return ratecard;
      })
    }));
  }

  render() {
    return (
      <Grid className="ratecard-grid">
        <Grid.Row>
          <Grid.Column width={16}>
            <p className="blue-text">
              As part of onboarding, we require all our doctor partners to offer
              a discounted fee
            </p>
            <List>
              <List.Item>
                <Icon name="circle" size="mini" color="orange" />
                <List.Content>
                  To make OPD insurance network valuable for the customers
                </List.Content>
              </List.Item>
              <List.Item>
                <Icon name="circle" size="mini" color="orange" />
                <List.Content>
                  To increase business opportunities for clinics
                </List.Content>
              </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row only="computer tablet">
          <Grid.Column>
            <Table unstackable compact size="small">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>DOCTOR NAME</Table.HeaderCell>
                  <Table.HeaderCell>SPECIALITY</Table.HeaderCell>
                  <Table.HeaderCell>CURRENT FEE</Table.HeaderCell>
                  <Table.HeaderCell>DISCOUNTED FEE</Table.HeaderCell>
                  <Table.HeaderCell>APPROVE</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {Array.isArray(this.state.ratecards)
                  ? this.state.ratecards.map(ratecard => (
                      <Table.Row key={ratecard.id}>
                        <Table.Cell>{ratecard.name || ''}</Table.Cell>
                        <Table.Cell>{ratecard.speciality || '-'}</Table.Cell>
                        <Table.Cell textAlign="center">
                          {ratecard.abs_rate ? `₹ ${ratecard.abs_rate}` : '-'}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {ratecard.sample_rate
                            ? `₹ ${ratecard.sample_rate}`
                            : '-'}
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          <Button
                            size="mini"
                            icon="check"
                            circular
                            color={ratecard.checked ? 'green' : 'grey'}
                            onClick={() => this.toggleRatecard(ratecard.id)}
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))
                  : null}
              </Table.Body>
            </Table>
            <p className="disclaimer">
              *The discounted rates shown above are based on the rates agreed by
              the current pool of already on-boarded doctors (3000+ in T7
              cities).
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row only="mobile">
          <Table unstackable compact size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>DOCTOR NAME</Table.HeaderCell>
                <Table.HeaderCell>CURRENT FEE</Table.HeaderCell>
                <Table.HeaderCell>DISCOUNTED FEE</Table.HeaderCell>
                <Table.HeaderCell>APPROVE</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {Array.isArray(this.state.ratecards)
                ? this.state.ratecards.map(ratecard => (
                    <Table.Row key={ratecard.id}>
                      <Table.Cell>
                        <Header as="h4">
                          <Header.Content>
                            {ratecard.name || ''}
                            <Header.Subheader>
                              {ratecard.speciality || '-'}
                            </Header.Subheader>
                          </Header.Content>
                        </Header>
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {ratecard.abs_rate ? `₹ ${ratecard.abs_rate}` : '-'}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {ratecard.sample_rate
                          ? `₹ ${ratecard.sample_rate}`
                          : '-'}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <Button
                          size="mini"
                          icon="check"
                          circular
                          color={ratecard.checked ? 'green' : 'grey'}
                          onClick={() => this.toggleRatecard(ratecard.id)}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ))
                : null}
            </Table.Body>
          </Table>
          <p className="disclaimer">
            *The discounted rates shown above are based on the rates agreed by
            the current pool of already on-boarded doctors (3000+ in T7 cities).
          </p>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Checkbox
              className="checkbox"
              checked={this.state.checked}
              onChange={this.toggleCheckbox}
            />
            I have read and agree to the
            <span
              className="terms-text"
              onClick={() =>
                this.setState({
                  openTermsModal: true
                })
              }
            >
              {' '}
              terms and conditions
            </span>.
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row className="stackable" columns={1} textAlign="right">
          <Grid.Column floated="right" width={6}>
            {this.state.source === 'ray' && (
              <Button
                size="tiny"
                loading={this.state.isCancelBtnLoading}
                onClick={this.handleCancel}
              >
                Cancel
              </Button>
            )}

            <Button
              disabled={!this.state.checked}
              size="tiny"
              loading={this.state.isAgreeBtnLoading}
              color="yellow"
              onClick={this.handleSubmit}
            >
              I agree and proceed
            </Button>
          </Grid.Column>
        </Grid.Row>
        {this.state.openModal && (
          <SuccessModal
            actionText="OKAY"
            handleClick={this.closeModal}
            header="Something went wrong"
            message="Please try again."
            open={this.state.openModal}
            size="mini"
          />
        )}
        {this.state.openTermsModal && (
          <TermsModal
            open={this.state.openTermsModal}
            handleClose={this.closeTermsModal}
            ratecards={this.state.ratecards}
            practice={this.state.practice}
          />
        )}
      </Grid>
    );
  }
}

RatecardPage.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default RatecardPage;
