
  import React from 'react'
  import { MDXTag } from '@mdx-js/tag'
  

export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Practo Terms and Conditions for Practitioner Network`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}>{`Practo Technologies Private Limited (`}<MDXTag name="strong" components={components} parentName="p">{`"`}{`Practo`}{`"`}</MDXTag>{`) offers a subscription based offering which is made available through the internet resource www.practo.com and the mobile application 'Practo' (together, "Website"). The following are the terms and conditions ("Terms and Conditions" / "Agreement") applicable to You (as defined hereunder) for rendering consultation services to the Users (as defined) pursuant to arrangements permitted by Practo to its Users ("Service").`}</MDXTag>
<MDXTag name="ol" components={components}>
<MDXTag name="li" components={components} parentName="ol">
<MDXTag name="p" components={components} parentName="li"><MDXTag name="strong" components={components} parentName="p">{`NATURE AND APPLICABILITY OF TERMS`}</MDXTag></MDXTag>
<MDXTag name="p" components={components} parentName="li">{`a. Please carefully go through the Terms and Conditions which are applicable to your rendering of Service through Website. These Terms and Conditions apply to you (a healthcare provider including all its doctors (as provided in the Appendix hereto), agents, employees) ("You" / "Practitioner") in relation to an individual user who has opened and maintains a user account with Practo through `}<MDXTag name="a" components={components} parentName="p" props={{"href":"http://www.practo.com"}}>{`www.practo.com`}</MDXTag>{`, is bound by the terms set out in `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://www.practo.com/company/terms"}}>{`https://www.practo.com/company/terms`}</MDXTag>{`  and who is eligible to avail consultation services from Practitioner(s) as per this Agreement ("User"). These Users may also include individuals whose relationship with Practo is established at the behest of a 3rd party such as an insurer, employer or an organization (e.g. a bank) willing to extend the facility to its customers. You shall always be subject to the Terms and Conditions stated hereunder. You confirm that You have read, understood and agree to the Terms and Conditions stated hereunder.`}</MDXTag>
<MDXTag name="p" components={components} parentName="li">{`b.  This arrangement between You and Practo shall be effective from the date you agree to these Terms and Conditions ("Effective  Date") and shall be valid unless terminated as per the termination process set out in clause 5 hereunder.`}</MDXTag>
<MDXTag name="p" components={components} parentName="li">{`If you have any questions about any part of these Terms and Conditions, feel free to contact us at +91 88805 88999.`}</MDXTag>
<MDXTag name="p" components={components} parentName="li">{`c.  By accepting these Terms and Conditions, you irrevocably accept all the conditions stipulated in this Agreement in relation to the Service, and agree to abide by them. This Agreement supersedes all previous oral and written terms and conditions (if any) communicated to you relating to your rendering the Service. By rendering the Service, you signify your acceptance of the terms of this Agreement.`}</MDXTag>
<MDXTag name="p" components={components} parentName="li">{`d.  We reserve the right to modify or terminate any portion of the Agreement for any reason and at any time, and such modifications shall be informed to you in writing. You should read the Agreement at regular intervals.`}</MDXTag>
<MDXTag name="p" components={components} parentName="li">{`e.  You acknowledge that you will be bound by this Agreement for rendering the Service.`}</MDXTag>
<MDXTag name="p" components={components} parentName="li">{`f.  The Agreement is published in compliance of, and is governed by the provisions of Indian law, including but not limited to:`}</MDXTag>
<MDXTag name="ul" components={components} parentName="li">
<MDXTag name="li" components={components} parentName="ul">
<MDXTag name="p" components={components} parentName="li">{`the Indian Contract Act, 1872,`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ul">
<MDXTag name="p" components={components} parentName="li">{`the (Indian) Information Technology Act, 2000, and`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ul">
<MDXTag name="p" components={components} parentName="li">{`the rules, regulations, guidelines and clarifications framed there under, including the (Indian) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011 (the "SPI Rules").`}</MDXTag>
</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ol">
<MDXTag name="p" components={components} parentName="li"><MDXTag name="strong" components={components} parentName="p">{`TERMS AS APPLICABLE TO THE SERVICE`}</MDXTag></MDXTag>
<MDXTag name="p" components={components} parentName="li">{`a. The terms in this clause 2 are applicable for all the Practitioner who agree to render the Service:`}</MDXTag>
<MDXTag name="ul" components={components} parentName="li">
<MDXTag name="li" components={components} parentName="ul">
<MDXTag name="p" components={components} parentName="li">{`With effect from the date of acceptance of these Terms and Conditions, You shall provide the Service to all the Users in a precise, reliable and a professional manner as agreed to under this Agreement and in accordance with other requirements set out by Practo from time to time. In rendering the Services, You will offer consultation without collecting any fees directly from the User, and in accordance with such other terms as decided by Practo. You agree and acknowledge that the fees receivable (by You) towards providing the Service have been separately paid to You by Practo.`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ul">
<MDXTag name="p" components={components} parentName="li">{`You and Practo agree that the User would pay for such Service to Practo prior to availing the same. The User is required to fill in certain personal information and details in order to create a medical history and profile which will be accessible by You. Nothing in this Agreement shall obligate Practo to disclose the terms agreed by it with the Users in relation to the Services.  `}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ul">
<MDXTag name="p" components={components} parentName="li">{`The Practitioner shall exercise all due care and caution while providing medical consultation to the Users and follow duly established and accepted practice and protocols related to clinical establishments and medical practice while rendering Service herein.`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ul">
<MDXTag name="p" components={components} parentName="li">{`The Practitioner further agrees to upload legally valid prescriptions to each User's health records and shall also agree to and be available for online consult and follow -- ups for such User.`}</MDXTag>
</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ol">
<MDXTag name="p" components={components} parentName="li"><MDXTag name="strong" components={components} parentName="p">{`PAYMENT AND INVOICES`}</MDXTag></MDXTag>
<MDXTag name="p" components={components} parentName="li">{`a.  All payments in relation to the Services shall be made directly by Practo to the Practitioner. The Practitioner agrees to furnish the complete bank details to Practo so that Practo can remit the money to such Practitioner's account. The applicable fees in relation to the Service has been set out in Appendix attached hereto.`}</MDXTag>
<MDXTag name="p" components={components} parentName="li">{`b.  The Practitioner will raise an invoice on Practo every month and Practo will clear the invoice within ten (10) working days of receipt of the same. The Practitioner shall ensure that the invoice expressly indicates the amounts pertaining to the Service availed by the Users for that month. If there is any dispute regarding the amount in the invoice, both Practo and the Practitioner shall resolve the same within thirty (30) days of notice of the same. If the dispute between the Practitioner and Practo is unresolved, then both Practo and Practitioner will resolve the dispute as per clause 5 hereunder.`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ol">
<MDXTag name="p" components={components} parentName="li"><MDXTag name="strong" components={components} parentName="p">{`REPRESENTATIONS AND WARRANTIES OF THE PRACTITIONER`}</MDXTag></MDXTag>
<MDXTag name="p" components={components} parentName="li">{`a.  The Practitioner represents and warrants that the Practitioner has full power and authority and holds all relevant medical, professional qualifications, government licenses/registrations, relevant licenses, consents and approvals necessary to own its assets and to render Services.`}</MDXTag>
<MDXTag name="p" components={components} parentName="li">{`b.  The Practitioner has all the necessary power and authority and approvals to execute and deliver this Agreement and to perform all its obligations hereunder.`}</MDXTag>
<MDXTag name="p" components={components} parentName="li">{`c.  The Practitioner undertakes that it has requisite infrastructure, equipment, trained professionals with expertise required to provide Service herein.`}</MDXTag>
<MDXTag name="p" components={components} parentName="li">{`d.  The Practitioner undertakes to uphold all requirement of law applicable to it, its doctors and other employees. The Practitioner declares that it or its doctors have never convicted of any criminal offence.`}</MDXTag>
<MDXTag name="p" components={components} parentName="li">{`e.  The Practitioner and its doctors shall adhere to the professional standards and reasonable turn-around time while rendering the Service herein.`}</MDXTag>
<MDXTag name="p" components={components} parentName="li">{`f.  During the course of the performance of the Service hereunder, the Practitioner may get access to certain personal and confidential information of the User including the information shared by Practo with the Practitioner ("Confidential Information"). The Practitioner acknowledges and understands the importance of Confidential Information, and agrees to never disclose such Confidential Information to any third party and ensure adequate protection from any unauthorized disclosure or usage. The Practitioner further undertakes to bind all its doctors and employees with same obligation to protect confidentiality of the Confidential Information, whether during their employment/engagement with the Practitioner or thereafter.`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ol">
<MDXTag name="p" components={components} parentName="li"><MDXTag name="strong" components={components} parentName="p">{`TERMINATION`}</MDXTag></MDXTag>
<MDXTag name="p" components={components} parentName="li">{`a.  Either party may terminate this Agreement at any time without providing any reasons by giving prior written notice of sixty (60) days to the other party.`}</MDXTag>
<MDXTag name="p" components={components} parentName="li">{`b.  Practo shall have the right to terminate this Agreement immediately for material breach capable of remedy if such breach continues un-remedied for a period of fifteen (15) days after notice of breach.`}</MDXTag>
<MDXTag name="p" components={components} parentName="li">{`c.  Practo may terminate the Agreement forthwith on the actual, threatened or likely  insolvency, bankruptcy, liquidation or other analogous event of the  Practitioner. Practo may also terminate the Agreement in case of any fraud or misrepresentation,  on part of the Practitioner.`}</MDXTag>
<MDXTag name="p" components={components} parentName="li">{`d.  In the event of expiry or termination of this Agreement the following shall apply:`}</MDXTag>
<MDXTag name="ul" components={components} parentName="li">
<MDXTag name="li" components={components} parentName="ul">
<MDXTag name="p" components={components} parentName="li">{`rights and remedies of either party which may have accrued up to the date of termination or expiry shall continue in force.`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ul">
<MDXTag name="p" components={components} parentName="li">{`The provisions of clauses 4(f), 5, 6, 8 shall survive such expiry or termination.`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ul">
<MDXTag name="p" components={components} parentName="li">{`any User who is receiving the Service (where rendering extends over a period of time) on the date of expiry/termination of the Agreement be dealt with by the Practitioner to serve his/her best interests.`}</MDXTag>
</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ol">
<MDXTag name="p" components={components} parentName="li"><MDXTag name="strong" components={components} parentName="p">{`INDEMNITY AND LIMITATION OF LIABILITY`}</MDXTag></MDXTag>
<MDXTag name="p" components={components} parentName="li">{`a.  The Practitioner shall have adequate professional liability insurance cover for Service rendered by the Practitioner and its doctors herein and maintain the same throughout the period of this Agreement. Practo shall not be liable or responsible for any acts, omission or commission of the Practitioner and/or its doctors and other medical / non-medical staff of the Practitioner or any consequence of the Service.`}</MDXTag>
<MDXTag name="p" components={components} parentName="li">{`b.  If any claim, demand or dispute is raised or instituted by the User or third party against Practo for any damage or loss arising out of any acts or omission or any breach of terms of this Agreement for any deficiency in Service or the negligence by the Practitioner and/or its doctor (including employees, agents, contractors, sub-contractors or representatives etc. of the Clinic or its doctor), the Practitioner shall on demand fully indemnify and hold harmless Practo, its employees, directors and shareholders against such claims, demands or actions in every respect.`}</MDXTag>
<MDXTag name="p" components={components} parentName="li">{`c.  Practo shall be entitled to seek preliminary and other injunctive relief, to the extent necessary, to enjoin such breach and such injunctive relief shall be in addition to and in no way in limitation of, any and all other remedies or rights that Practo shall have as per applicable law.`}</MDXTag>
<MDXTag name="p" components={components} parentName="li">{`d.  In no event, including but not limited to negligence, shall Practo, or any of its directors, officers, employees, agents or content or service providers be liable for any loss of profit, business or revenue or any indirect, special, incidental, consequential, exemplary or punitive damages arising out of this Agreement.`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ol">
<MDXTag name="p" components={components} parentName="li"><MDXTag name="strong" components={components} parentName="p">{`RETENTION AND REMOVAL`}</MDXTag></MDXTag>
<MDXTag name="p" components={components} parentName="li">{`Practo may retain such information collected from Users from its Website or Service for as long as necessary, depending on the type of information; purpose, means and modes of usage of such information; and according to the SPI Rules. Computer web server logs may be preserved as long as administratively necessary.`}</MDXTag>
</MDXTag>
<MDXTag name="li" components={components} parentName="ol">
<MDXTag name="p" components={components} parentName="li"><MDXTag name="strong" components={components} parentName="p">{`APPLICABLE LAW AND DISPUTE SETTLEMENT`}</MDXTag></MDXTag>
<MDXTag name="p" components={components} parentName="li">{`a.  You agree that this Agreement and any contractual obligation between Practo and the Practitioner will be governed by the laws of India.`}</MDXTag>
<MDXTag name="p" components={components} parentName="li">{`b.  Any dispute, claim or controversy arising out of or relating to this Agreement shall be determined by arbitration in India, before a sole arbitrator appointed by Practo. Arbitration shall be conducted in accordance with the Arbitration and Conciliation Act, 1996. The seat of such arbitration shall be Bangalore. All proceedings of such arbitration, including, without limitation, any awards, shall be in the English language. The award shall be final and binding on the parties to the dispute.`}</MDXTag>
<MDXTag name="p" components={components} parentName="li">{`c. Subject to the above clause (b), the courts at Bengaluru shall have exclusive jurisdiction over any disputes arising out of or in relation to this Agreement.`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Appendix`}</MDXTag></MDXTag>
<MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`List of Doctors with the Practitioner`}</MDXTag></MDXTag></MDXTag>
  