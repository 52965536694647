import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Button, Divider, Grid, Image } from 'semantic-ui-react';
import illustration from '../../assets/images/illustration.png';
import { getHost } from '../../helpers/utils';

function handleClick() {
  if (window.self !== window.top) {
    window.parent.postMessage(
      {
        show_insurance_banner: false,
        message: 'Done clicked on step 3'
      },
      getHost('ray')
    );
  }
}

function ConfirmationPage({ location: { state: { source = '' } = {} } = {} }) {
  return (
    <Grid padded stackable className="success-grid">
      <Grid.Row>
        <Grid.Column width={10}>
          <div className="success-header">
            A big thanks for completing the process
          </div>
          <div className="success-header">
            <b>Welcome onboard!</b>
          </div>
          <div className="padding">
            <div className="blue-text subheader">You are now part of</div>
            <div className="blue-text subheader">
              <b>India’s largest and fastest growing Cashless OPD network</b>
            </div>
          </div>
        </Grid.Column>
        <Grid.Column width={6}>
          <Image size="medium" centered src={illustration} alt="Illustration" />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={10}>
          <p className="footer">
            Our personnel will get in touch with you soon for a bit of paperwork
            and orientation process.
          </p>
        </Grid.Column>
      </Grid.Row>
      {source === 'ray' && (
        <Fragment>
          <Divider />
          <Grid.Row columns={1} textAlign="right" only="computer tablet">
            <Grid.Column floated="right" width={3}>
              <Button size="tiny" color="yellow" onClick={handleClick}>
                Done
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row only="mobile">
            <Grid.Column>
              <Button fluid size="small" color="yellow" onClick={handleClick}>
                Done
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Fragment>
      )}
    </Grid>
  );
}

ConfirmationPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default ConfirmationPage;
