import PropTypes from 'prop-types';
import qs from 'query-string';
import React, { Component, Fragment } from 'react';
import {
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List
} from 'semantic-ui-react';
import illustration from '../../assets/images/illustration.png';
import { SuccessModal } from '../../components';
import {
  getPracticeDetailsFromToken,
  sendInterest
} from '../../helpers/lead-gen-api';

class LandingPage extends Component {
  constructor(props) {
    super(props);
    const { location: { search } = {} } = props;
    const { source, token, email } = qs.parse(search);
    this.state = {
      token,
      source,
      email,
      openModal: false,
      isLoading: false
    };

    this.closeModal = this.closeModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { history, match } = this.props;
    const { token, source, email } = this.state;
    const payload = {
      step: 1,
      interested: true,
      source
    };

    if (email) {
      payload.email = email;
    }

    this.setState({
      isLoading: true
    });

    Promise.all([
      sendInterest(token, payload),
      getPracticeDetailsFromToken(token)
    ])
      .then(([, second]) => second.data)
      .then(data => {
        this.setState({
          isLoading: false
        });

        history.push(`${match.url}/ratecard`, { data, token, source, email });
      })
      .catch(() => {
        this.setState({
          openModal: true,
          isLoading: false
        });
      });
  }

  closeModal() {
    this.setState({
      openModal: false
    });
  }

  renderHeader() {
    return (
      <div>
        <span className="main-header">
          <div>Introducing India’s first and largest</div>
          <div>
            <b>OPD Insurance Network</b>
          </div>
        </span>
        <p className="sign-up-text">Sign up for free!</p>
      </div>
    );
  }

  renderBenefitsList() {
    return (
      <List>
        <List.Item>
          <Icon name="circle" size="small" color="orange" />
          <List.Content>
            <List.Header>Grow your Practice</List.Header>
            <List.Description className="point-description">
              Increase footfall by tapping into the fast growing network of
              insurance and <b>Practo health subscription</b> customers
            </List.Description>
          </List.Content>
        </List.Item>
        <List.Item>
          <Icon name="circle" size="small" color="orange" />
          <List.Content>
            <List.Header>Stand out from peers</List.Header>
            <List.Description className="point-description">
              Increase the <b>clinic visibility</b> by getting listed onto
              insurer and Practo platforms.
            </List.Description>
          </List.Content>
        </List.Item>
        <List.Item>
          <Icon name="circle" size="small" color="orange" />
          <List.Content>
            <List.Header>Real-time authorization</List.Header>
            <List.Description className="point-description">
              Easy 3-step process for filing claims online.{' '}
              <b>Instant approval</b> without any paperwork.
            </List.Description>
          </List.Content>
        </List.Item>
        <List.Item>
          <Icon name="circle" size="small" color="orange" />
          <List.Content>
            <List.Header>Hassle-free payments</List.Header>
            <List.Description className="point-description">
              Quick and <b>guaranteed settlements</b> with an easy to access
              earnings dashboard.
            </List.Description>
          </List.Content>
        </List.Item>
      </List>
    );
  }

  render() {
    return (
      <Fragment>
        <Grid padded stackable>
          <Grid.Row columns={2} className="intro-row" only="mobile">
            <Grid.Column width={10} className="intro-column">
              {this.renderHeader()}
              <Grid.Column>
                <Image
                  size="medium"
                  centered
                  src={illustration}
                  alt="Illustration"
                />
              </Grid.Column>
              <Grid.Column>
                <Button
                  fluid
                  loading={this.state.isLoading}
                  color="yellow"
                  size="big"
                  onClick={this.handleClick}
                >
                  I am interested
                </Button>
              </Grid.Column>
              {this.renderBenefitsList()}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={2} className="intro-row" only="tablet computer">
            <Grid.Column width={10} className="intro-column">
              {this.renderHeader()}
              {this.renderBenefitsList()}
            </Grid.Column>
            <Grid.Column width={6}>
              <Image
                size="medium"
                centered
                src={illustration}
                alt="Illustration"
              />
            </Grid.Column>
          </Grid.Row>
          <Divider />
        </Grid>
        <Grid padded>
          <Grid.Row className="status-row" centered columns={1}>
            <Grid.Column className="status-column" width={12}>
              <Grid padded stackable>
                <Grid.Row>
                  <Grid.Column
                    verticalAlign="middle"
                    textAlign="right"
                    className="status-counter"
                    width={4}
                  >
                    <div className="number">3k+</div>
                    <div className="blue-text">Doctors</div>
                  </Grid.Column>
                  <Grid.Column className="status-text" width={12}>
                    <Header className="status-header" as="h6">
                      Onboarded members
                    </Header>
                    <div className="description">
                      Already LIVE with <b>3000+</b> clinics across top cities
                      in India and growing fast
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid padded textAlign="center" className="footer-grid">
          <Grid.Row>
            <Button
              loading={this.state.isLoading}
              color="yellow"
              size="big"
              onClick={this.handleClick}
            >
              I am interested
            </Button>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <div className="footer-title">
                Please click above to show your interest
              </div>
            </Grid.Column>
            <Grid.Column>
              <div className="footer-subtitle">
                We will get in touch asap to explain things in more details
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <div className="copyright-text">
              &copy; 2018, Practo Technologies Pvt. Ltd. All Rights Reserved
            </div>
          </Grid.Row>
        </Grid>
        {this.state.openModal && (
          <SuccessModal
            actionText="OKAY"
            handleClick={this.closeModal}
            header="Something went wrong"
            message="Please try again."
            open={this.state.openModal}
            size="mini"
          />
        )}
      </Fragment>
    );
  }
}

LandingPage.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default LandingPage;
