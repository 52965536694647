import axios from 'axios';
import { readBrowserCookie } from './cookie';

import routingConstants from './../constants/routes';

const headerConfig = {
  headers: {
    'x-insurance-token': readBrowserCookie('X-Insurance-Token')
  }
};

const api = axios.create(headerConfig);

const getUserDetails = () => api.get(routingConstants.GET_USER_DETAILS);

const getUsers = () => api.get(routingConstants.GET_USERS);

const getAllSpecialities = payorId =>
  api.get(routingConstants.GET_ALL_SPECIALITIES, {
    params: {
      payor_id: payorId
    }
  });

const getAllCities = payorId =>
  api.get(routingConstants.GET_ALL_CITIES, {
    params: {
      payor_id: payorId
    }
  });

const getLocalities = (payorId, city) =>
  api.get(routingConstants.GET_ALL_LOCALITIES, {
    params: {
      payor_id: payorId,
      city
    }
  });

const getPayorPlans = payorId =>
  api.get(`${routingConstants.GET_PAYOR_PLANS}/${payorId}/policies`);

const getDoctors = ({ payor, ...payload }) =>
  api.get(`${routingConstants.GET_PAYOR_PLANS}/${payor}/doctors`, {
    params: payload
  });

const shortListDoctors = (payorId, policy_id, doctor_ids) =>
  api.patch(`${routingConstants.GET_PAYOR_PLANS}/${payorId}/onboardDoctors`, {
    policy_id,
    doctor_ids: doctor_ids.join(','),
    type: 'SHORTLISTED'
  });

const unlistDoctors = (payorId, policy_id, doctor_ids) =>
  api.patch(`${routingConstants.GET_PAYOR_PLANS}/${payorId}/offboardDoctors`, {
    policy_id,
    doctor_ids: doctor_ids.join(','),
    type: 'SHORTLISTED'
  });

const unmarkDoctorCashless = (payorId, payload) =>
  api.patch(
    `${routingConstants.GET_PAYOR_PLANS}/${payorId}/unmarkCashless`,
    payload
  );

const getRatecard = ratecardId =>
  api.get(`${routingConstants.GET_RATECARD}/${ratecardId}`);

const uploadContract = (doctorPolicyId, files) => {
  if (!(files && Array.isArray(files))) return false;

  const payload = new FormData();
  payload.append('file', files[0]);

  return api.post(
    `${routingConstants.DOCTOR_POLICIES}/${doctorPolicyId}/contract`,
    payload
  );
};

const getMasterProcedures = payorId =>
  api.get(`${routingConstants.GET_PAYOR_PLANS}/${payorId}/procedures`);

const sendDocuments = payload =>
  api.post(
    `${routingConstants.DOCTOR_POLICIES}/${payload.doctor_policy_id}/documents`,
    payload
  );

const fetchContractLink = key =>
  api.get(routingConstants.FETCH_CONTRACT_URL, {
    params: { key }
  });

const getDoctorsCount = ({ payor, ...payload }) =>
  api.get(`${routingConstants.GET_PAYOR_PLANS}/${payor}/doctors/count`, {
    params: payload
  });

const downloadCSV = ({ payor, ...payload }, type) =>
  api.get(`${routingConstants.GET_PAYOR_PLANS}/${payor}/doctors/download`, {
    params: {
      ...payload,
      type
    },
    responseType: 'blob'
  });

const downloadQR = practiceId =>
  api.get(`${routingConstants.PRACTICES}/${practiceId}/qrcode`, {
    responseType: 'blob'
  });

const sendBankDetails = payload => {
  const { document: [file = {}] = [], ...rest } = payload;

  const formData = new FormData();
  for (let item in rest) {
    formData.append(item, rest[item]);
  }

  formData.append('file', file);

  return api.put('/api/v1/doctors/submerchants/upsert', formData, {
    timeout: 10000
  });
};

const fetchBankDetails = doctorId => {
  return api.get('/api/v1/doctors/submerchants', {
    params: {
      doctor_id: doctorId
    }
  });
};

const fetchDocument = doctorId => {
  return api.get('api/v1/doctors/submerchants/view/cheque', {
    params: {
      doctor_id: doctorId
    }
  });
};

export default {
  downloadCSV,
  downloadQR,
  fetchBankDetails,
  fetchContractLink,
  fetchDocument,
  getAllCities,
  getAllSpecialities,
  getDoctors,
  getDoctorsCount,
  getLocalities,
  getMasterProcedures,
  getPayorPlans,
  getRatecard,
  getUserDetails,
  getUsers,
  sendBankDetails,
  sendDocuments,
  shortListDoctors,
  unlistDoctors,
  unmarkDoctorCashless,
  uploadContract
};
