const messages = {
  unmarkCashlessText:
    'You are about to remove this doctor from cashless network. The current rate card will be disabled',
  removeShortlistedDoctorsText:
    'Only Reimbursement doctors will be removed. Do you want to proceed?',
  discardUploadModalText:
    'You will lose the changes you have made if you navigate away before sending it for approval.',
  successModalHeader: 'Successfully sent for approval',
  successModalText:
    'The contract and rate card for the respective doctor has been successfully sent for approval. The status will get updated eventually.',
  uploadErrorText:
    'There was an error in sending it for approval. Please retry or you can dimiss this entry.'
};

export default messages;
