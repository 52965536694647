import PropTypes from 'prop-types';
import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

function Spinner({ text = 'Loading' }) {
  return (
    <div className="l-spinner-segment">
      <Dimmer active inverted>
        <Loader inverted content={text} />
      </Dimmer>
    </div>
  );
}

Spinner.propTypes = {
  text: PropTypes.string
};

export default Spinner;
