import PropTypes from 'prop-types';
import React from 'react';
import { Container, Dropdown, Image, Menu } from 'semantic-ui-react';
import practoLogo from '../../assets/images/practo_logo_v2.svg';

function Navbar({ user, size = 'small', fixed = null }) {
  return (
    <Menu fixed={fixed} stackable size={size}>
      <Container fluid>
        <Menu.Item className="practo-logo">
          <Image src={practoLogo} alt="Practo logo" />
        </Menu.Item>
        {user &&
          user.isLoggedIn && (
            <Menu.Menu position="right">
              <Dropdown item text={user.name}>
                <Dropdown.Menu>
                  <a href="/logout">
                    <Dropdown.Item>Sign Out</Dropdown.Item>
                  </a>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>
          )}
      </Container>
    </Menu>
  );
}

Navbar.propTypes = {
  fixed: PropTypes.string,
  size: PropTypes.string,
  user: PropTypes.object
};

export default Navbar;
