import React from 'react';
import PropTypes from 'prop-types';

function Checkbox({ checked, className }) {
  return (
    <span className={className}>
      <input
        className="styled-checkbox"
        id="styled-checkbox-1"
        type="checkbox"
        readOnly
        checked={checked}
      />
      <label htmlFor="styled-checkbox-1" />
    </span>
  );
}

Checkbox.propTypes = {
  checked: PropTypes.any,
  className: PropTypes.string
};

export default Checkbox;
