import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Input, Button, Divider } from 'semantic-ui-react';

class EditMode extends Component {
  constructor() {
    super();
    this.state = {
      rate: ''
    };
  }

  static validate(value) {
    return Number(value) <= 0 ? 0 : value.replace(/^0+/, '');
  }

  render() {
    const {
      addProcedureCost,
      code,
      master_procedure_id,
      name,
      removeProcedure
    } = this.props;
    return (
      <Fragment>
        <Grid.Column width={3}>
          <Input
            placeholder="Rate"
            fluid
            type="number"
            value={this.state.rate}
            onChange={(e, data) => {
              const rate = EditMode.validate(data.value);
              this.setState({
                rate
              });
              addProcedureCost({
                master_procedure_id,
                rate,
                name,
                code
              });
            }}
          />
        </Grid.Column>
        <Grid.Column>
          <Button
            circular
            color="red"
            basic
            size="mini"
            icon="close"
            onClick={() => removeProcedure(master_procedure_id)}
          />
        </Grid.Column>
      </Fragment>
    );
  }
}

EditMode.propTypes = {
  addProcedureCost: PropTypes.func.isRequired,
  removeProcedure: PropTypes.func.isRequired,
  master_procedure_id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired
};

function ProcedureRow({ name, rate, readOnly, ...props }) {
  return (
    <Fragment>
      <Grid.Row>
        <Grid.Column width={12}>{name}</Grid.Column>
        {readOnly ? (
          <Grid.Column width={4}>{rate}</Grid.Column>
        ) : (
          <EditMode readOnly={readOnly} {...props} name={name} />
        )}
      </Grid.Row>
      <Divider />
    </Fragment>
  );
}

ProcedureRow.propTypes = {
  name: PropTypes.string.isRequired,
  rate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  readOnly: PropTypes.bool.isRequired
};

export default ProcedureRow;
