import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Tab } from 'semantic-ui-react';
import {
  CardContainer,
  FiltersBar,
  FooterMenu,
  SuccessModal
} from '../../components';
import { updateUserDetailsAction } from '../../actions/user';
import { unselectAllDoctors } from '../../actions/doctors';
import { fetchDoctors, fetchDoctorsCount } from '../../actions/doctors';
import { setTabType, clearFieldsAction } from '../../actions/filters';
import { resetError } from '../../actions/error';
class Dashboard extends Component {
  constructor() {
    super();
    this.handleTabChange = this.handleTabChange.bind(this);
    this.validateAndSend = this.validateAndSend.bind(this);
    this.debouncedsubmit = debounce(this.submit.bind(this), 300);
  }

  validateAndSend() {
    const { values = {} } = this.props.filter;
    if (!(values.hasOwnProperty('payor') && values.hasOwnProperty('policy_id')))
      return;

    if (values.offset) {
      values.offset = 0;
    }
    this.debouncedsubmit(values);
  }

  handleTabChange(event, data) {
    const tabType = data.activeIndex === 0 ? 'MASTER' : 'SHORTLISTED';
    if (tabType === this.props.type) return;

    if (data.activeIndex === 0)
      this.props.clearFieldsAction('filter', ['onboarded_status']);

    this.props.setTabType(data.activeIndex).then(() => {
      this.validateAndSend();
    });
  }

  submit(values = {}) {
    const { type } = this.props;

    const filters = { ...values, type };
    this.props.unselectAllDoctors();
    this.props.fetchDoctors(filters);
    if (type !== 'MASTER') {
      this.props.fetchDoctorsCount(filters);
    }
  }

  panes() {
    const { doctorDataLoading, type } = this.props;
    return [
      {
        menuItem: 'Master Data List',
        render: () => (
          <Tab.Pane loading={type === 'MASTER' && doctorDataLoading}>
            <FiltersBar onSubmit={this.debouncedsubmit} />
            <CardContainer reload={this.validateAndSend} />
            <FooterMenu reload={this.validateAndSend} />
          </Tab.Pane>
        )
      },
      {
        menuItem: 'Shortlisted Doctor',
        render: () => (
          <Tab.Pane loading={type === 'SHORTLISTED' && doctorDataLoading}>
            <FiltersBar onSubmit={this.debouncedsubmit} />
            <CardContainer reload={this.validateAndSend} />
            <FooterMenu reload={this.validateAndSend} />
          </Tab.Pane>
        )
      }
    ];
  }

  componentWillMount() {
    this.props.updateUserDetailsAction();
  }
  render() {
    return (
      <Container className="dashboard-container">
        <Tab
          className="dashboard-tab"
          panes={this.panes()}
          onTabChange={this.handleTabChange}
        />
        {this.props.error.on && (
          <SuccessModal
            actionText="OKAY"
            handleClick={this.props.resetError}
            header={this.props.error.message || 'Something went wrong'}
            message="Please try again."
            open={this.props.error.on}
            size="mini"
          />
        )}
      </Container>
    );
  }
}

Dashboard.propTypes = {
  clearFieldsAction: PropTypes.func.isRequired,
  doctorDataLoading: PropTypes.bool.isRequired,
  error: PropTypes.object.isRequired,
  fetchDoctors: PropTypes.func.isRequired,
  fetchDoctorsCount: PropTypes.func.isRequired,
  filter: PropTypes.object,
  resetError: PropTypes.func.isRequired,
  setTabType: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  unselectAllDoctors: PropTypes.func.isRequired,
  updateUserDetailsAction: PropTypes.func.isRequired
};

export default connect(
  state => ({
    doctorDataLoading: state.doctor.isLoading,
    error: state.error,
    filter: state.form.filter,
    type: state.masterData.type
  }),
  {
    clearFieldsAction,
    fetchDoctors,
    fetchDoctorsCount,
    resetError,
    setTabType,
    updateUserDetailsAction,
    unselectAllDoctors
  }
)(Dashboard);
