import ClinicInfo from './ClinicInfo';
import ConfirmationPage from './ConfirmationPage';
import LandingPage from './LandingPage';
import Main from './Main';
import PopupPage from './PopupPage';
import RatecardPage from './RatecardPage';
import RatecardTable from './RatecardTable';
import Terms from './Terms';

export {
  ClinicInfo,
  ConfirmationPage,
  LandingPage,
  Main,
  PopupPage,
  RatecardPage,
  RatecardTable,
  Terms
};
