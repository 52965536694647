import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Button, Grid, Icon, Modal } from 'semantic-ui-react';
import { ConfirmModal, DropdownFilter, FileUpload, SuccessModal } from '..';
import {
  fetchBankDetails,
  sendBankDetails,
  fetchDocument
} from '../../actions/bank-details';
import messages from '../../constants/messages';
import { SearchInput } from '../form';

class UploadBankDetailsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledButton: false,
      openConfirm: false,
      openSuccess: false,
      openError: false
    };

    this.checkAndOpenModal = this.checkAndOpenModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeAndClearState = this.closeAndClearState.bind(this);
    this.sendBankDetails = this.sendBankDetails.bind(this);
  }

  checkAndOpenModal() {
    this.props.handleClose();
  }

  closeAndClearState() {
    const { handleClose } = this.props;
    this.closeModal('success');
    handleClose();
  }

  openModal(modalName) {
    switch (modalName) {
    case 'confirm':
      return this.setState({
        openConfirm: true
      });
    case 'success':
      return this.setState({
        openSuccess: true
      });
    case 'error':
      return this.setState({
        openError: true
      });
    }
  }

  closeModal(modalName) {
    switch (modalName) {
    case 'confirm':
      return this.setState({
        openConfirm: false
      });
    case 'success':
      return this.setState({
        openSuccess: false
      });
    case 'error':
      return this.setState({
        openError: false
      });
    }
  }

  getAccountTypes() {
    return ['Current', 'Saving'].map(item => ({
      text: `Account Type: ${item}`,
      value: item
    }));
  }

  static isRequired(value) {
    return value ? undefined : 'required';
  }

  sendBankDetails(values) {
    const { confirm_bank_account_number, ...restValues } = values;
    const payload = {
      ...restValues,
      doctor_id: this.props.doctor_id
    };
    this.props.sendBankDetails(payload).then(() => this.openModal('success'));
  }

  componentDidMount() {
    this.props
      .fetchBankDetails(this.props.doctor_id)
      .then(() => this.props.fetchDocument(this.props.doctor_id));
  }

  render() {
    const { open, handleSubmit } = this.props;
    return (
      <Modal
        className="ratecard-modal"
        open={open}
        style={{ marginTop: 0 }} // Fixes issue with Modal positioning
        closeIcon={<Icon name="close" onClick={this.checkAndOpenModal} />}
      >
        <Modal.Header>Bank Details</Modal.Header>
        <Modal.Content className="upload-modal-content" scrolling>
          <Modal.Description>
            <form
              id="bank-details"
              onSubmit={handleSubmit(this.sendBankDetails)}
            >
              {this.state.openConfirm && (
                <ConfirmModal
                  open={this.state.openConfirm}
                  className="unmark-confirm"
                  cancelButton="YES, EXIT"
                  confirmButton="CONTINUE"
                  content={messages.discardUploadModalText}
                  header="Are you sure?"
                  onCancel={this.discardAndClose}
                  onConfirm={() => this.closeModal('confirm')}
                  size="small"
                />
              )}
              {this.state.openSuccess && (
                <SuccessModal
                  actionText="OKAY"
                  handleClick={this.closeAndClearState}
                  header="Success"
                  message="Bank details has been successfully updated"
                  open={this.state.openSuccess}
                />
              )}
              {this.state.openError && (
                <ConfirmModal
                  open={this.state.openError}
                  className="unmark-confirm"
                  cancelButton="DO IT LATER"
                  confirmButton="RETRY"
                  content={messages.uploadErrorText}
                  header="Something went wrong"
                  onCancel={this.discardAndClose}
                  onConfirm={() => this.closeModal('error')}
                  size="tiny"
                />
              )}
              <Grid className="modal-grid">
                <Grid.Row columns={2} className="modal-row">
                  <Grid.Column width={8}>
                    <Field
                      component={SearchInput}
                      name="account_holder_name"
                      validate={[UploadBankDetailsModal.isRequired]}
                      placeholder="Name of the account holder"
                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Field
                      component={SearchInput}
                      name="ifsc_code"
                      validate={[UploadBankDetailsModal.isRequired]}
                      placeholder="IFSC Code"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="modal-row">
                  <Grid.Column width={8}>
                    <Field
                      component={SearchInput}
                      name="bank_account_number"
                      validate={[UploadBankDetailsModal.isRequired]}
                      placeholder="Bank Account Number"
                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Field
                      component={SearchInput}
                      name="confirm_bank_account_number"
                      validate={[UploadBankDetailsModal.isRequired]}
                      placeholder="Confirm Bank Account Number"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="modal-row">
                  <Grid.Column width={8}>
                    <Field
                      component={SearchInput}
                      name="pan_number"
                      validate={[UploadBankDetailsModal.isRequired]}
                      placeholder="PAN Number"
                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Field
                      name="account_type"
                      component={DropdownFilter}
                      placeholder="Select Account Type"
                      validate={[UploadBankDetailsModal.isRequired]}
                      search
                      selection
                      options={this.getAccountTypes()}
                    />
                  </Grid.Column>
                </Grid.Row>
                {this.props.fileUrl ? (
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <a
                        href={this.props.fileUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Button
                          type="button"
                          size="small"
                          compact
                          color="yellow"
                        >
                          View
                        </Button>
                      </a>
                    </Grid.Column>
                  </Grid.Row>
                ) : null}
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Field
                      name="document"
                      accept="application/pdf, image/jpeg, image/png"
                      validate={[UploadBankDetailsModal.isRequired]}
                      component={FileUpload}
                      placeholder="Upload Document"
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="yellow" compact form="bank-details">
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

UploadBankDetailsModal.propTypes = {
  doctor_id: PropTypes.any,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  bankDetails: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  sendBankDetails: PropTypes.func.isRequired,
  fetchBankDetails: PropTypes.func.isRequired,
  fetchDocument: PropTypes.func.isRequired,
  fileUrl: PropTypes.string.isRequired
};

export default connect(
  state => ({
    filters: state.form.filter,
    initialValues: state.bank.data,
    fileUrl: state.bank.fileUrl
  }),
  {
    sendBankDetails,
    fetchBankDetails,
    fetchDocument
  }
)(
  reduxForm({
    form: 'bankDetails',
    enableReinitialize: true,
    validate: values => {
      const errors = {};
      const { bank_account_number, confirm_bank_account_number } = values;

      if (
        bank_account_number &&
        confirm_bank_account_number &&
        bank_account_number !== confirm_bank_account_number
      ) {
        errors.confirm_bank_account_number =
          'Bank account number should be same';
      }

      return errors;
    }
  })(
    connect(state => ({
      bankDetails: state.form.bankDetails
    }))(UploadBankDetailsModal)
  )
);
