import pick from 'lodash/pick';
import {
  DOWNLOAD_QRCODE,
  DOWNLOAD_QRCODE_SUCCESS,
  DOWNLOAD_QRCODE_ERROR,
  DOWNLOAD_SHORTLISTED_DOCTORS,
  DOWNLOAD_SHORTLISTED_DOCTORS_ERROR,
  DOWNLOAD_SHORTLISTED_DOCTORS_SUCCESS,
  FETCH_DOCTORS,
  FETCH_DOCTORS_COUNT,
  FETCH_DOCTORS_COUNT_ERROR,
  FETCH_DOCTORS_COUNT_SUCCESS,
  FETCH_DOCTORS_ERROR,
  FETCH_DOCTORS_SUCCESS,
  SET_INITIAL_DOCTOR_STATE,
  SHORTLIST_DOCTORS,
  SHORTLIST_DOCTORS_ERROR,
  SHORTLIST_DOCTORS_SUCCESS,
  TOGGLE_DOCTOR,
  UNLIST_DOCTORS,
  UNLIST_DOCTORS_ERROR,
  UNLIST_DOCTORS_SUCCESS,
  UNMARK_CASHLESS,
  UNMARK_CASHLESS_ERROR,
  UNMARK_CASHLESS_SUCCESS,
  UNSELECT_ALL_DOCTORS
} from '../constants/doctorTypes';
import { SHOW_ERROR } from '../constants/errorTypes';
import api from '../helpers/api';
import store from '../store';

export const fetchDoctors = payload => {
  return dispatch => {
    dispatch({
      type: FETCH_DOCTORS
    });

    return api
      .getDoctors(payload)
      .then(({ data }) => {
        dispatch({
          type: FETCH_DOCTORS_SUCCESS,
          data
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_DOCTORS_ERROR,
          error
        });
        dispatch({ type: SHOW_ERROR });
      });
  };
};

const _getValuesFromFilterForm = (...args) => {
  const { form: { filter: { values = {} } = {} } = {} } = store.getState();

  return pick(values, args);
};

export const toggleDoctor = doctorId => {
  return dispatch => {
    dispatch({
      type: TOGGLE_DOCTOR,
      data: doctorId
    });
  };
};

export const unselectAllDoctors = () => {
  return dispatch => {
    dispatch({
      type: UNSELECT_ALL_DOCTORS
    });
  };
};

export const shortlistDoctors = doctorIds => {
  const { payor, policy_id } = _getValuesFromFilterForm('payor', 'policy_id');
  return dispatch => {
    dispatch({
      type: SHORTLIST_DOCTORS
    });

    return api
      .shortListDoctors(payor, policy_id, doctorIds)
      .then(({ data }) => {
        dispatch({
          type: SHORTLIST_DOCTORS_SUCCESS,
          data
        });
      })
      .catch(error => {
        dispatch({
          type: SHORTLIST_DOCTORS_ERROR,
          error
        });
        dispatch({ type: SHOW_ERROR });
      });
  };
};

export const unlistDoctors = doctorIds => {
  const { payor, policy_id } = _getValuesFromFilterForm('payor', 'policy_id');
  return dispatch => {
    dispatch({
      type: UNLIST_DOCTORS
    });

    return api
      .unlistDoctors(payor, policy_id, doctorIds)
      .then(({ data }) => {
        dispatch({
          type: UNLIST_DOCTORS_SUCCESS,
          data
        });
      })
      .catch(error => {
        dispatch({
          type: UNLIST_DOCTORS_ERROR,
          error
        });
        dispatch({ type: SHOW_ERROR });
      });
  };
};

export const unmarkCashless = payload => {
  const { payor } = _getValuesFromFilterForm('payor');
  return dispatch => {
    dispatch({
      type: UNMARK_CASHLESS
    });

    return api
      .unmarkDoctorCashless(payor, payload)
      .then(({ data }) => {
        dispatch({
          type: UNMARK_CASHLESS_SUCCESS,
          data
        });
      })
      .catch(error => {
        dispatch({
          type: UNMARK_CASHLESS_ERROR,
          error
        });
        dispatch({ type: SHOW_ERROR });
        throw new Error('Unable to unmark doctor cashless');
      });
  };
};

export const resetDoctorList = () => {
  return dispatch => {
    dispatch({
      type: SET_INITIAL_DOCTOR_STATE
    });
  };
};

export const fetchDoctorsCount = payload => {
  return dispatch => {
    dispatch({
      type: FETCH_DOCTORS_COUNT
    });

    return api
      .getDoctorsCount(payload)
      .then(({ data }) => {
        dispatch({
          type: FETCH_DOCTORS_COUNT_SUCCESS,
          data
        });
      })
      .catch(error => {
        dispatch({
          type: FETCH_DOCTORS_COUNT_ERROR,
          error
        });
        dispatch({ type: SHOW_ERROR });
      });
  };
};

export const downloadShortlistedDoctors = (payload, type) => {
  return dispatch => {
    dispatch({
      type: DOWNLOAD_SHORTLISTED_DOCTORS
    });

    return api
      .downloadCSV(payload, type)
      .then(({ data }) => {
        dispatch({
          type: DOWNLOAD_SHORTLISTED_DOCTORS_SUCCESS,
          data
        });
        return data;
      })
      .catch(error => {
        dispatch({
          type: DOWNLOAD_SHORTLISTED_DOCTORS_ERROR,
          error
        });
        dispatch({ type: SHOW_ERROR });
        throw new Error('Unable to download Shortlisted Doctors');
      });
  };
};

export const downloadQRCode = (practiceId, doctorId) => {
  return dispatch => {
    dispatch({ type: DOWNLOAD_QRCODE, doctorId });

    return api
      .downloadQR(practiceId)
      .then(({ data }) => {
        dispatch({
          type: DOWNLOAD_QRCODE_SUCCESS,
          data
        });

        return data;
      })
      .catch(error => {
        dispatch({ type: DOWNLOAD_QRCODE_ERROR, error });
        dispatch({ type: SHOW_ERROR });
        throw new Error('Unable to download QR Code');
      });
  };
};
