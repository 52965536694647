import * as OfflinePluginRuntime from 'offline-plugin/runtime';
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import { App, Main } from './containers';
import store from './store';

OfflinePluginRuntime.install();

const AppWithStore = (
  <Provider store={store}>
    <Router>
      <Fragment>
        <Route exact path="/" component={App} />
        <Route strict path="/invite" component={Main} />
      </Fragment>
    </Router>
  </Provider>
);

export default ReactDOM.render(AppWithStore, document.getElementById('root'));
