import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

function DropdownFilter({
  input,
  placeholder,
  options,
  handleChange,
  readOnly,
  ...props
}) {
  return (
    <Dropdown
      fluid
      placeholder={placeholder}
      options={options}
      value={input.value}
      onChange={(event, data) => {
        if (handleChange) {
          handleChange(data.value);
        }
        return readOnly ? false : input.onChange(data.value);
      }}
      {...props}
    />
  );
}

DropdownFilter.propTypes = {
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  input: PropTypes.object.isRequired,
  handleChange: PropTypes.func,
  readOnly: PropTypes.bool
};

export default DropdownFilter;
