export function readBrowserCookie(cookieName) {
  if (typeof document !== 'undefined') {
    const cookies = document.cookie.split(';');
    const regex = RegExp(`^\\s*${cookieName}=\\s*(.*?)\\s*$`);
    const requiredCookie = cookies.filter(cookie => cookie.match(regex))[0];
    if (requiredCookie) {
      // return value of the cookie
      return requiredCookie.split('=')[1];
    }
  } else {
    return '';
  }
}
