import differenceBy from 'lodash/differenceBy';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Button, Divider, Grid, Icon, Modal } from 'semantic-ui-react';
import {
  ConfirmModal,
  DoctorInfo,
  DropdownFilter,
  FileUpload,
  ProcedureRow,
  SuccessModal
} from '..';
import { resetContract, uploadContract } from '../../actions/contract';
import {
  addProcedure,
  addProcedureCost,
  clearProcedures,
  removeProcedure,
  sendDocuments,
  resetDocuments
} from '../../actions/procedures';
import messages from '../../constants/messages';

class UploadRatecardModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledButton: false,
      openConfirm: false,
      openSuccess: false,
      openError: false
    };

    this.handleDrop = this.handleDrop.bind(this);
    this.addProcedure = this.addProcedure.bind(this);
    this.proceduresList = this.proceduresList.bind(this);
    this.sendDocuments = this.sendDocuments.bind(this);
    this.discardAndClose = this.discardAndClose.bind(this);
    this.checkAndOpenModal = this.checkAndOpenModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.closeAndClearState = this.closeAndClearState.bind(this);
  }

  handleDrop(fileToUpload) {
    const { doctor_policy_id, uploadContract } = this.props;
    uploadContract(doctor_policy_id, fileToUpload);
  }

  addProcedure(value) {
    const { procedures, addProcedure } = this.props;
    const selectedProcedure = procedures.find(
      procedure => procedure.master_procedure_id === value
    );

    addProcedure(selectedProcedure);
  }

  proceduresList() {
    const { procedures, selectedProcedures } = this.props;
    const proceduresToList = differenceBy(
      procedures,
      selectedProcedures,
      'master_procedure_id'
    );
    return proceduresToList.map((procedure, index) => ({
      text: procedure.name,
      value: procedure.master_procedure_id,
      key: `${procedure.master_procedure_id}-${index}`
    }));
  }

  static getDerivedStateFromProps(props, state) {
    state.disabledButton = !(
      props.selectedProcedures.length &&
      props.selectedProcedures.every(
        item => item.hasOwnProperty('rate') && Number(item.rate) >= 0
      )
    );

    state.openError = props.contract.error.on || props.documentsError.on;
    return state;
  }

  sendDocuments() {
    const {
      doctor_id,
      policy_id,
      doctor_policy_id,
      selectedProcedures: procedures,
      filters: { values: { payor: payor_id = {} } } = {},
      contract: { data: { key: contract_url } = {} } = {},
      sendDocuments
    } = this.props;

    const payload = {
      doctor_id,
      policy_id,
      doctor_policy_id,
      procedures,
      payor_id,
      contract_url
    };

    sendDocuments(payload).then(() => this.openModal('success'));
  }

  discardAndClose() {
    const {
      clearProcedures,
      resetContract,
      handleClose,
      resetDocuments
    } = this.props;
    clearProcedures();
    resetContract();
    resetDocuments();
    if (this.state.openConfirm) {
      this.closeModal('confirm');
    } else {
      this.closeModal('error');
    }
    handleClose();
  }

  checkAndOpenModal() {
    if (this.props.contract.data.key) {
      return this.openModal('confirm');
    }
    this.props.handleClose();
  }

  closeAndClearState() {
    const {
      clearProcedures,
      resetContract,
      resetDocuments,
      handleClose,
      reload
    } = this.props;
    resetContract();
    resetDocuments();
    clearProcedures();
    this.closeModal('success');
    handleClose();
    reload();
  }

  openModal(modalName) {
    switch (modalName) {
    case 'confirm':
      return this.setState({
        openConfirm: true
      });
    case 'success':
      return this.setState({
        openSuccess: true
      });
    case 'error':
      return this.setState({
        openError: true
      });
    }
  }

  closeModal(modalName) {
    switch (modalName) {
    case 'confirm':
      return this.setState({
        openConfirm: false
      });
    case 'success':
      return this.setState({
        openSuccess: false
      });
    case 'error':
      return this.setState({
        openError: false
      });
    }
  }

  render() {
    const {
      open,
      contract,
      selectedProcedures,
      addProcedureCost,
      removeProcedure
    } = this.props;
    return (
      <Modal
        className="ratecard-modal"
        open={open}
        style={{ marginTop: 0 }} // Fixes issue with Modal positioning
        closeIcon={<Icon name="close" onClick={this.checkAndOpenModal} />}
      >
        <Modal.Header>Onboard Doctor</Modal.Header>
        <Modal.Content className="upload-modal-content" scrolling>
          <Modal.Description>
            {this.state.openConfirm && (
              <ConfirmModal
                open={this.state.openConfirm}
                className="unmark-confirm"
                cancelButton="YES, EXIT"
                confirmButton="CONTINUE"
                content={messages.discardUploadModalText}
                header="Are you sure?"
                onCancel={this.discardAndClose}
                onConfirm={() => this.closeModal('confirm')}
                size="small"
              />
            )}
            {this.state.openSuccess && (
              <SuccessModal
                actionText="OKAY"
                handleClick={this.closeAndClearState}
                header={messages.successModalHeader}
                message={messages.successModalText}
                open={this.state.openSuccess}
              />
            )}
            {this.state.openError && (
              <ConfirmModal
                open={this.state.openError}
                className="unmark-confirm"
                cancelButton="DO IT LATER"
                confirmButton="RETRY"
                content={messages.uploadErrorText}
                header="Something went wrong"
                onCancel={this.discardAndClose}
                onConfirm={() => this.closeModal('error')}
                size="tiny"
              />
            )}
            <Grid className="modal-grid">
              <Grid.Row columns={2} className="modal-row">
                <Grid.Column width={3}>
                  <span className="index">1</span>
                  <span className="header">Clinic Contract</span>
                </Grid.Column>
                <Grid.Column width={12}>
                  <Field
                    name="contract"
                    accept="application/pdf, image/jpeg, image/png"
                    component={FileUpload}
                    handleDrop={this.handleDrop}
                    isLoading={contract.isLoading}
                    contractKey={contract.data.key}
                  />
                </Grid.Column>
              </Grid.Row>
              <Divider />
              <Grid.Row className="modal-row">
                <Grid.Column width={3}>
                  <span className="index">2</span>
                  <span className="header">Rate Card</span>
                </Grid.Column>
                <Grid.Column width={16}>
                  <DoctorInfo className="doctor-info" {...this.props} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="procedure-header">
                <Grid.Column width={12}>PROCEDURE NAME</Grid.Column>
                <Grid.Column width={4}>COST (₹)</Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Field
                    name="ratecard"
                    component={DropdownFilter}
                    search
                    selection
                    labeled
                    readOnly={true}
                    icon="search"
                    disabled={!Boolean(contract.data.key)}
                    placeholder="Search and add procedures"
                    options={this.proceduresList()}
                    handleChange={this.addProcedure}
                  />
                </Grid.Column>
              </Grid.Row>
              {selectedProcedures.length > 0 &&
                selectedProcedures.map(procedure => (
                  <ProcedureRow
                    {...procedure}
                    addProcedureCost={addProcedureCost}
                    removeProcedure={removeProcedure}
                    key={procedure.master_procedure_id}
                    readOnly={false}
                  />
                ))}
            </Grid>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="yellow"
            compact
            loading={this.props.documentUploading}
            disabled={this.state.disabledButton}
            onClick={this.sendDocuments}
          >
            Send for approval
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

UploadRatecardModal.propTypes = {
  contract: PropTypes.object.isRequired,
  documentsError: PropTypes.object.isRequired,
  documentUploading: PropTypes.bool.isRequired,
  doctor_id: PropTypes.any,
  policy_id: PropTypes.any,
  doctor_policy_id: PropTypes.any,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  uploadContract: PropTypes.func.isRequired,
  procedures: PropTypes.array.isRequired,
  selectedProcedures: PropTypes.array.isRequired,
  addProcedure: PropTypes.func.isRequired,
  addProcedureCost: PropTypes.func.isRequired,
  removeProcedure: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  sendDocuments: PropTypes.func.isRequired,
  reload: PropTypes.func.isRequired,
  resetContract: PropTypes.func.isRequired,
  clearProcedures: PropTypes.func.isRequired,
  resetDocuments: PropTypes.func.isRequired
};

export default connect(
  state => ({
    filters: state.form.filter,
    contract: state.contract,
    documentsError: state.documents.error,
    documentUploading: state.documents.isLoading,
    procedures: state.procedures.data,
    selectedProcedures: state.procedures.selected
  }),
  {
    uploadContract,
    addProcedure,
    addProcedureCost,
    removeProcedure,
    sendDocuments,
    resetContract,
    clearProcedures,
    resetDocuments
  }
)(
  reduxForm({
    form: 'documents'
  })(UploadRatecardModal)
);
