import { SHOW_ERROR } from '../constants/errorTypes';
import {
  GET_USER_DETAILS,
  GET_USER_DETAILS_ERROR,
  GET_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS,
  UPDATE_USER_DETAILS_ERROR,
  UPDATE_USER_DETAILS_SUCCESS
} from '../constants/userTypes';
import api from '../helpers/api';

export const getUserDetailsAction = () => {
  return dispatch => {
    dispatch({
      type: GET_USER_DETAILS
    });

    api
      .getUserDetails()
      .then(({ data }) => {
        dispatch({
          type: GET_USER_DETAILS_SUCCESS,
          data
        });
      })
      .catch(error => {
        dispatch({
          type: GET_USER_DETAILS_ERROR,
          error
        });
        dispatch({ type: SHOW_ERROR });
      });
  };
};

export const updateUserDetailsAction = () => {
  return dispatch => {
    dispatch({
      type: UPDATE_USER_DETAILS
    });
    api
      .getUsers()
      .then(({ data }) => {
        if (Array.isArray(data)) {
          var newUser = data.filter(item => item.source !== 'RAY')[0];

          dispatch({
            type: UPDATE_USER_DETAILS_SUCCESS,
            data: newUser
          });
        } else {
          dispatch({
            type: UPDATE_USER_DETAILS_SUCCESS,
            data
          });
        }
      })
      .catch(error => {
        dispatch({
          type: UPDATE_USER_DETAILS_ERROR,
          error
        });
        dispatch({ type: SHOW_ERROR });
      });
  };
};
