import {
  ADD_PROCEDURE,
  ADD_PROCEDURE_COST,
  CLEAR_PROCEDURES,
  FETCH_PROCEDURES,
  FETCH_PROCEDURES_ERROR,
  FETCH_PROCEDURES_SUCCESS,
  REMOVE_PROCEDURE
} from '../constants/ratecardTypes';

const INITIAL_STATE = {
  isLoading: false,
  id: null,
  data: [],
  selected: [],
  error: {
    on: false,
    message: ''
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case FETCH_PROCEDURES:
    return {
      ...state,
      isLoading: true,
      id: action.id
    };
  case FETCH_PROCEDURES_SUCCESS:
    return {
      ...state,
      isLoading: false,
      data: action.data.procedures
    };
  case FETCH_PROCEDURES_ERROR:
    return {
      ...state,
      isLoading: false,
      error: {
        on: true,
        message: 'Could not fetch ratecard'
      }
    };
  case ADD_PROCEDURE:
    return {
      ...state,
      selected: [...state.selected, action.procedure]
    };
  case REMOVE_PROCEDURE:
    return {
      ...state,
      selected: [
        ...state.selected.filter(
            item => item.master_procedure_id !== action.procedureId
          )
      ]
    };

  case ADD_PROCEDURE_COST:
    return {
      ...state,
      selected: state.selected.map(
          item =>
            action.payload.master_procedure_id === item.master_procedure_id
              ? action.payload
              : item
        )
    };

  case CLEAR_PROCEDURES:
    return {
      ...state,
      selected: []
    };

  default:
    return state;
  }
};
