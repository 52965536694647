import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'semantic-ui-react';

function SearchInput({
  input,
  placeholder,
  meta: { touched, error },
  ...rest
}) {
  return (
    <Input
      placeholder={placeholder}
      value={input.value}
      fluid
      error={touched && !!error}
      onChange={(event, data) => {
        input.onChange(data.value);
      }}
      {...rest}
      {...input}
    />
  );
}

SearchInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};

export default SearchInput;
