import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dashboard } from '.';
import { AccessDeniedCard, LoginCard, Navbar, Spinner } from '../../components';
import { getUserDetailsAction } from '../../actions/user';
import { readBrowserCookie } from '../../helpers/cookie';
import '../../styles/app/main.scss';

class App extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    if (this.isAuthenticated()) {
      this.props.getUserDetailsAction();
    }
  }

  isAuthenticated() {
    return (
      !!readBrowserCookie('sso') && !!readBrowserCookie('X-Insurance-Token')
    );
  }

  componentDidMount() {
    this.setState({
      data: this.props.user.data
    });
  }

  redirectToLogin() {
    window.location = '/login';
  }

  render() {
    const user = this.props.user;
    let role;
    if (user.data && user.data.role) {
      role = user.data.role.toUpperCase();
    }
    if (user.error.on) this.redirectToLogin();
    if (user.isLoading) return <Spinner />;
    return (
      <React.Fragment>
        <Navbar user={user.data} fixed="top" />
        {this.isAuthenticated() ? (
          !(role === 'MODERATOR' || role === 'ADMIN') ? (
            <AccessDeniedCard name={user.data.name} />
          ) : (
            <Dashboard />
          )
        ) : (
          <LoginCard />
        )}
      </React.Fragment>
    );
  }
}

App.propTypes = {
  user: PropTypes.object.isRequired,
  getUserDetailsAction: PropTypes.func.isRequired
};

export default connect(
  state => ({
    user: state.user
  }),
  {
    getUserDetailsAction
  }
)(App);
